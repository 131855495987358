<template>
  <div class="scroll-wrapper about-wrapper news-wrapper">
    <div class="top-wrapper">
      <div class="bg"></div>
      <div class="title">关于我们</div>
      <div class="subtitle">让新能源设计变得轻松简单</div>
    </div>
    <div class="intro-wrapper">
      <div class="section section-1">
        <div class="section-title">
          <div class="title-text">公司简介</div>
          <img class="title-icon" :src="`${ossPath}/assets/about/company_icon.png`" />
        </div>
        <div class="intro-text">
          <div class="intro-text-item">
            绿虫光伏与储能仿真平台，作为新能源领域工程师的专属高效创新工具，精心打造以赋能每位从业者，使他们能够迅速掌握工程师级技能，从零开始迅速构建出优秀的光伏与储能系统解决方案。
          </div>
          <div class="intro-text-item">
            通过傻瓜式的功能界面，用户可以轻松规划光伏设计布局，配置项目参数，并深度融合前沿AI技术，结合逆变型号、组件型号、气象辐照等42种条件，运用自研核心算法，计算出最准确的发电量，智能推荐最优解决方案。平台还配备了丰富的PPT模板库，根据项目不同的行业特点，即可一键生成专业且富有内容的PPT方案，只需3分钟，就能拥有一套既完整又准确的光伏与储能方案展示材料。
          </div>
          <div class="intro-text-item">
            未来，绿虫科技将持续推动技术创新与功能融合，致力于将平台的每一分潜力转化为推动新能源领域发展的实际动力。通过不断优化升级，绿虫将不仅成为工程师们手中的利器，更将引领整个行业迈向更加高效、智能的能源管理新时代，共同绘制新能源发展的宏伟蓝图。
          </div>
        </div>
      </div>
      <div class="section section-2">
        <div class="section-title">
          <div class="title-text">联系我们</div>
          <img class="title-icon" :src="`${ossPath}/assets/about/wechat_icon.png`" />
        </div>
        <div class="contact-info">
          <div class="contact-left">
            <div class="company-name">江苏绿虫信息科技有限公司</div>
            <div class="contact-item">
              <div class="contact-label">电话：</div>
              <div class="contact-value">400-999-3358</div>
            </div>
            <div class="contact-item">
              <div class="contact-label">地址：</div>
              <div class="contact-value">江苏省徐州市云龙区云龙华府广场A1栋1601室</div>
            </div>
          </div>
        </div>
        <img class="contact-img" :src="`${ossPath}/assets/about/map_img.png`" />
      </div>
    </div>
    <div class="footer-placeholder"></div>
    <MToolBar />
  </div>
</template>

<script>
import MToolBar from "@/components/ToolBar/m_index.vue";

export default {
  name: "About",
  components: { MToolBar },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/m_news.scss";
@import "@/assets/scss/m_section-title.scss";
@import "@/assets/scss/m_scroll-wrapper.scss";
.about-wrapper {
  .top-wrapper {
    .bg {
      background: url("#{$oss-path}/assets/about/m_bg.png") no-repeat center center / cover;
      height: 520px;
    }
  }
  .intro-wrapper {
    margin: 337px auto 0;
    padding: 0 60px;
    .intro-text {
      margin-top: 80px;
      text-indent: 2em;
      word-break: break-all;
      font-size: 42px;
      line-height: 78px;
      color: #666666;
    }
  }
  .section-2 {
    margin-top: 202px;
    margin-bottom: 100px;
    .contact-info {
      margin-top: 100px;
      display: flex;
      justify-content: space-between;
      .company-name {
        font-size: 48px;
        color: #222222;
        line-height: 70px;
        user-select: text;
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        -o-user-select: text;
      }
      .contact-item {
        margin-top: 40px;
        display: flex;
        line-height: 60px;
        font-size: 36px;
        .contact-label {
          flex: none;
          color: #999999;
        }
        .contact-value {
          word-break: break-all;
          color: #222222;
          user-select: text;
          -webkit-user-select: text;
          -moz-user-select: text;
          -ms-user-select: text;
          -o-user-select: text;
        }
      }
    }
    .contact-img {
      margin-top: 80px;
      width: 100%;
      height: 532px;
    }
  }
}
</style>
