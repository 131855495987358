<template>
  <div class="header-wrapper">
    <img class="header-logo" :src="`${ossPath}/assets/header/logo.png`" @click="changeNav(0)" />
    <img class="nav-icon" :src="`${ossPath}/assets/header/nav_icon.png`" @click="openNav" />
    <div class="nav-wrapper" v-if="showNav" @click="closeNav">
      <div class="nav-header" @click.stop>
        <img class="close-icon" :src="`${ossPath}/assets/header/close_icon.png`" @click="closeNav" />
      </div>
      <div class="nav-list" @click.stop>
        <div :class="['nav-item', index == activeIndex ? 'nav-item-active' : '']" v-for="(item, index) in navList" :key="index" @click="changeNav(index)">
          <img class="nav-icon" :src="`${ossPath}/assets/header/${item.icon}_icon.png`" />
          <div class="nav-name">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "m_Header",
  data() {
    return {
      navList: [
        {
          name: "首页",
          icon: "home",
          address: ["/m_index"],
        },
        {
          name: "下载中心",
          icon: "download",
          address: ["/m_download"],
        },
        {
          name: "新闻中心",
          icon: "news",
          address: ["/m_news", "/m_newsdetail"],
        },
        {
          name: "关于我们",
          icon: "about",
          address: ["/m_about"],
        },
      ],
      activeIndex: 0,
      showNav: false,
    };
  },
  created() {
    this.activeIndex = this.navList.findIndex((item) =>
      item.address.some((addr) => {
        const regex = new RegExp(`^${addr}(/|$)`); // 匹配以 addr 为开头的路径，后面可以跟着斜杠或路径结束
        return regex.test(this.$route.path);
      })
    );
  },
  methods: {
    // 路由跳转
    changeNav(index) {
      if (index == this.activeIndex && this.$route.path == this.navList[this.activeIndex].address[0]) return;
      this.activeIndex = index;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$router.push(this.navList[this.activeIndex].address[0]);
      this.closeNav();
    },
    openNav() {
      this.showNav = true;
    },
    closeNav() {
      this.showNav = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 188px;
  padding-left: 46px;
  padding-right: 44px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  .header-logo {
    flex: none;
    width: 346px;
    height: 90px;
  }
  .nav-icon {
    flex: none;
    width: 46px;
    height: 46px;
  }
  .nav-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    .nav-header {
      width: 100%;
      height: 188px;
      padding-left: 46px;
      padding-right: 44px;
      box-sizing: border-box;
      background-color: #fff;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .close-icon {
        width: 46px;
        height: 46px;
      }
    }
    .nav-list {
      padding: 28px 108px 60px;
      background-color: #fff;
      .nav-item {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 80px;
        }
        .nav-icon {
          width: 90px;
          height: 90px;
          margin-right: 30px;
        }
        .nav-name {
          font-weight: 500;
          font-size: 46px;
          color: #222222;
        }
        &.nav-item-active .nav-name {
          color: #3470ff;
        }
      }
    }
  }
}
</style>
