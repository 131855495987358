<template>
  <div class="news-wrapper">
    <div class="bg"></div>
    <div class="title">新闻中心</div>
    <div class="subtitle">了解企业最新资讯动态</div>
    <div class="detail-wrapper" v-if="newsDetail">
      <div class="back-btn" @click="toList">{{ `< 返回列表` }}</div>
          <div class="detail-title">{{ newsDetail.post_title }}</div>
          <div class="detail-time">{{ newsDetail.published_time.slice(0, 11).replace(/-/g, ".") }}</div>
          <div class="divider divider-1"></div>
          <div class="detail-content" v-html="newsDetail.post_content"></div>
          <div class="divider divider-2"></div>
          <div :class="['change-div', 'prev', lastNewsInfo ? '' : 'no-hover']">
            <div class="change-label">上一篇：</div>
            <div class="change-value" @click="toDetail(lastNewsInfo.id)">{{ lastNewsInfo ? lastNewsInfo.post_title : "无"
              }}</div>
          </div>
          <div :class="['change-div', 'next', nextNewsInfo ? '' : 'no-hover']">
            <div class="change-label">下一篇：</div>
            <div class="change-value" @click="toDetail(nextNewsInfo.id)">{{ nextNewsInfo ? nextNewsInfo.post_title : "无"
              }}</div>
          </div>
      </div>
    </div>
</template>

<script>
import { getArticles, getArticleDetail } from "@/api/api";

export default {
  name: "NewsDetail",
  // 页面路由更新
  beforeRouteUpdate(to, from, next) {
    this.newsId = to.params.id;
    this.getAllNewsList();
    next();
  },
  data() {
    return {
      newsId: this.$route.params.id,
      newsDetail: null,
      allNewsList: [],
      lastNewsInfo: null,
      nextNewsInfo: null,
    };
  },
  created() {
    this.getAllNewsList();
  },
  methods: {
    getAllNewsList() {
      if (!this.$store.state.allNewsList.length) {
        getArticles({ page: 1, limit: 99999 }).then((res) => {
          this.allNewsList = res.articles;
          this.$store.commit("SETALLNEWSLIST", res.articles);
          this.getArticleDetail();
        });
      } else {
        this.allNewsList = this.$store.state.allNewsList;
        this.getArticleDetail();
      }
    },
    getArticleDetail() {
      getArticleDetail(this.newsId)
        .then((res) => {
          this.newsDetail = res;
          let newsIndex = this.allNewsList.findIndex((item) => item.id == this.newsDetail.id);
          if (newsIndex != -1) {
            let lastNewsIndex = newsIndex - 1;
            let nextNewsIndex = newsIndex + 1;
            this.lastNewsInfo = lastNewsIndex >= 0 ? this.allNewsList[lastNewsIndex] : null;
            this.nextNewsInfo = nextNewsIndex <= this.allNewsList.length ? this.allNewsList[nextNewsIndex] : null;
          }

        })
        .catch((err) => { });
    },
    toList() {
      this.$router.push("/news");
    },
    toDetail(id) {
      if (id == this.newsId) return;
      this.$router.push(`/newsdetail/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/news.scss";

.news-wrapper {
  .detail-wrapper {
    width: 1420px;
    margin: 198px auto 80px;

    .back-btn {
      width: 120px;
      height: 44px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #c3c3c3;
      box-sizing: border-box;
      font-size: 16px;
      color: #999999;
      text-align: center;
      line-height: 44px;
      cursor: pointer;
    }

    .detail-title {
      margin-top: 56px;
      font-weight: bold;
      font-size: 40px;
      color: #000000;
      text-align: center;
      line-height: 58px;
      word-break: break-all;
    }

    .detail-time {
      margin-top: 30px;
      font-size: 20px;
      color: #999999;
      text-align: center;
      line-height: 29px;
      word-break: break-all;
    }

    .divider {
      width: 100%;
      height: 2px;
      background: #e6e6e6;

      &.divider-1 {
        margin: 60px 0;
      }

      &.divider-2 {
        margin: 80px 0 40px;
      }
    }

    .detail-content {
      // font-size: 18px;
      color: #444444;
      // line-height: 32px;
      text-indent: 2em;

      /deep/ p {
        margin: 40px 0;
      }

      /deep/ img {
        max-width: 100% !important;
        height: auto !important;
        display: block;
        margin: 0 auto;
      }
    }

    .change-div {
      display: flex;
      padding: 0 20px;
      line-height: 26px;

      .change-label {
        font-weight: 500;
        font-size: 18px;
        color: #222222;
      }

      .change-value {
        font-size: 18px;
        color: #666666;
        word-break: break-all;
      }

      &:not(.no-hover) .change-value:hover {
        color: #3470ff;
        text-decoration-line: underline;
        cursor: pointer;
      }

      &.no-hover .change-value {
        pointer-events: none;
      }

      &.next {
        margin-top: 20px;
      }
    }
  }
}
</style>
