<template>
  <div class="news-wrapper">
    <div class="bg"></div>
    <div class="title">新闻中心</div>
    <div class="subtitle">了解企业最新资讯动态</div>
    <div class="hightlight-news">
      <el-carousel ref="carousel" arrow="never" height="454px" trigger="click" :interval="5000"
        indicator-position="outside">
        <el-carousel-item v-for="item in topNewsList" :key="item.id">
          <div class="swiper-item">
            <el-image class="swiper-item-img" :src="item.thumbnail" fit="cover"></el-image>
            <div class="swiper-item-info">
              <div class="swiper-item-title">{{ item.post_title }}</div>
              <div class="swiper-item-detail">{{ item.post_excerpt }}</div>
              <div class="detail-btn" @click="toDetail(item.id)">查看详情 >></div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="search-row">
      <div class="nav-list">
        <div :class="['nav-item', index == activeNavIndex ? 'active-nav-item' : '']"
          v-for="(item, index) in categoryList" :key="index" @click="changeNav(index)">
          <div class="nav-item-title">{{ item.name }}</div>
          <div class="active-bar"></div>
        </div>
      </div>
      <el-input placeholder="请输入关键词搜索" v-model="keyword" @keyup.enter.native="search">
        <img class="search-icon" slot="suffix" :src="`${ossPath}/assets/news/search_icon.png`" />
      </el-input>
    </div>
    <div class="news-list" v-if="newsList.length">
      <div :class="['news-item', item.thumbnail ? 'has-img' : '']" v-for="item in newsList" :key="item.id"
        @click="toDetail(item.id)">
        <div class="news-date">
          <div class="year-month">{{ item.year }}.{{ item.month }}</div>
          <div class="day">{{ item.day }}</div>
        </div>
        <div class="news-info">
          <div class="news-title">{{ item.post_title }}</div>
          <div class="news-detail">{{ item.post_excerpt }}</div>
        </div>
        <el-image class="news-img" :src="item.thumbnail" fit="cover" v-if="item.thumbnail"></el-image>
      </div>
    </div>
    <el-empty description="暂无新闻" v-else></el-empty>
    <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
      :currentPage="currentPage" :pageSize="pageSize" :total="totalNum" :pagerCount="5" prev-text="上一页" next-text="下一页">
    </el-pagination>
  </div>
</template>

<script>
import { getCategories, getArticles } from "@/api/api";
import { api_url } from "@/utils/index";

export default {
  name: "News",
  data() {
    return {
      categoryList: [],
      topNewsList: [],
      newsList: [],
      activeNavIndex: 0,
      keyword: "",
      currentPage: 1,
      pageSize: 5,
      totalNum: 0,
    };
  },
  created() {
    this.getCategories();
    this.getRecommendNews();
  },
  methods: {
    changeNav(index) {
      if (this.activeNavIndex == index) return;
      this.activeNavIndex = index;
      this.keyword = "";
      this.search();
    },
    toDetail(id) {
      this.$router.push(`/newsdetail/${id}`);
    },
    getCategories() {
      getCategories()
        .then((res) => {
          this.categoryList = res.list;
          this.search();
        })
        .catch((err) => { });
    },
    getRecommendNews() {
      getArticles({ recommended: 1 })
        .then((res) => {
          res.articles.forEach((item) => {
            item.thumbnail = `${api_url}${item.thumbnail}`;
          });
          this.topNewsList = res.articles;
        })
        .catch((err) => { });
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    getList() {
      let categoryId = this.categoryList[this.activeNavIndex].id;
      getArticles({ page: this.currentPage, limit: this.pageSize, keyword: this.keyword || undefined, category_ids: categoryId })
        .then((res) => {
          res.articles.forEach((item) => {
            let date = new Date(item.published_time * 1000);
            item.year = date.getFullYear();
            item.month = (date.getMonth() + 1).toString().padStart(2, "0");
            item.day = date.getDate().toString().padStart(2, "0");
            item.thumbnail = `${api_url}${item.thumbnail}`;
          });
          this.newsList = res.articles;
          this.totalNum = res.total;
        })
        .catch((err) => { });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/news.scss";

.news-wrapper {
  .hightlight-news {
    margin: 59px auto 0;
    width: fit-content;
    background: #ffffff;
    box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    padding: 40px;
    padding-left: 36px;
    padding-bottom: 36px;
    box-sizing: border-box;

    /deep/.el-carousel {
      overflow: hidden;

      .el-carousel__container {
        width: 1340px;
        height: 454px !important;
      }

      .el-carousel__indicators {
        position: absolute;
        left: auto;
        right: 6px;
        transform: none;
        bottom: -8px;

        .el-carousel__indicator {
          padding: 6px 5px;

          .el-carousel__button {
            width: 30px;
            height: 8px;
            background: #e6e6e6;
            border-radius: 60px;
            opacity: 1;
          }

          &.is-active {
            .el-carousel__button {
              background: #3470ff;
            }
          }
        }
      }
    }

    .swiper-item {
      display: flex;
      justify-content: space-between;

      .swiper-item-img {
        width: 720px;
        height: 450px;
        margin-bottom: 4px;
        margin-left: 4px;
        border-radius: 16px;
      }

      .swiper-item-info {
        .swiper-item-title {
          margin-top: 20px;
          width: 570px;
          font-weight: bold;
          font-size: 30px;
          color: #222222;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
        }

        .swiper-item-detail {
          width: 576px;
          margin-top: 30px;
          font-size: 16px;
          color: #666666;
          line-height: 32px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
          word-break: break-all;
        }

        .detail-btn {
          margin-top: 154px;
          width: 160px;
          height: 44px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #3470ff;
          box-sizing: border-box;
          font-size: 16px;
          color: #3470ff;
          text-align: center;
          line-height: 44px;
          cursor: pointer;

          &:hover {
            background: #3470ff;
            color: #ffffff;
          }
        }
      }
    }
  }

  .search-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1420px;
    margin: 60px auto 49px;

    .nav-list {
      display: flex;
      align-items: center;

      .nav-item {
        cursor: pointer;
        position: relative;

        .nav-item-title {
          font-size: 28px;
          color: #222222;
          line-height: 46px;
          transition: all 0.2s;
        }

        &:not(:last-child) {
          margin-right: 60px;
        }

        &.active-nav-item {
          .nav-item-title {
            font-weight: bold;
            font-size: 32px;
            color: #3470ff;
          }

          .active-bar {
            width: 48px;
          }
        }

        .active-bar {
          pointer-events: none;
          position: absolute;
          width: 0;
          height: 4px;
          background: #3470ff;
          border-radius: 90px;
          left: 0;
          right: 0;
          bottom: -14px;
          margin: auto;
          transition: width 0.2s;
        }
      }
    }

    /deep/ .el-input {
      width: 500px;

      .el-input__inner {
        height: 58px;
        border-radius: 6px;
        border: 1px solid #e6e6e6;
        padding-left: 20px;
        padding-right: 64px;
        font-size: 18px;
        line-height: 26px;
        color: #666666;

        &::placeholder {
          color: #c3c3c3;
        }
      }

      .el-input__suffix {
        width: 24px;
        height: 24px;
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto;

        .el-input__suffix-inner,
        .el-input__suffix-inner .search-icon {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }

  .news-list {
    width: 1420px;
    margin: 0 auto;

    .news-item {
      cursor: pointer;
      padding: 60px 0;
      display: flex;

      &:not(:last-child) {
        border-bottom: 2px solid #e6e6e6;
      }

      &.has-img .news-date,
      &.has-img .news-info {
        margin-top: 30px;
      }

      .news-date {
        width: 96px;
        height: 130px;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #c3c3c3;
        box-sizing: border-box;
        color: #999999;
        text-align: center;
        margin-right: 50px;

        .year-month {
          margin-top: 30px;
          font-size: 16px;
          line-height: 24px;
        }

        .day {
          font-weight: bold;
          font-size: 30px;
          line-height: 44px;
        }
      }

      .news-info {
        flex: 1;
        min-width: 0;

        .news-title {
          font-weight: bold;
          font-size: 28px;
          color: #222222;
          line-height: 40px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
        }

        .news-detail {
          margin-top: 20px;
          font-size: 18px;
          color: #666666;
          line-height: 32px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          word-break: break-all;
        }
      }

      .news-img {
        width: 400px;
        height: 225px;
        margin-left: 64px;
        border-radius: 10px;
        display: block;
      }
    }
  }

  /deep/ .el-empty {
    padding: 40px 0;

    .el-empty__image {
      width: 160px;
    }

    .el-empty__description {
      margin-top: 20px;

      p {
        color: #666666;
      }
    }
  }

  /deep/ .el-pagination {
    padding: 0;
    margin: 68px auto 80px;
    width: fit-content;

    .btn-prev,
    .btn-next {
      width: 74px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #d6d6d6;
      box-sizing: border-box;
      margin: 0 5px;
      min-width: 0;

      span {
        min-width: 0;
        font-size: 14px;
        color: #222222;
        height: 40px;
        line-height: 40px;
      }
    }

    .number {
      width: 40px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d6d6d6;
      box-sizing: border-box;
      padding: 0;
      font-size: 14px;
      color: #222222 !important;
      line-height: 40px;
      font-weight: 400;
      margin: 0 5px;
      min-width: 0;

      &.active {
        border-color: #3470ff;
        color: #ffffff !important;
        background-color: #3470ff !important;
      }
    }

    .el-icon {
      width: 40px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d6d6d6;
      box-sizing: border-box;
      padding: 0;
      color: #222222 !important;
      margin: 0 5px;
      min-width: 0;

      &::before {
        display: block;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
      }

      &.el-icon-d-arrow-right::before,
      &.el-icon-d-arrow-left::before {
        content: "\e794";
      }
    }
  }
}
</style>
