<template>
  <div class="header-wrapper" :style="{ background: backgroundColor }">
    <img class="header-logo" :src="`${ossPath}/assets/header/logo.png`" @click="changeNav(0)" />
    <div class="nav-list">
      <div :class="['nav-item', index == activeIndex ? 'nav-item-active' : '']" v-for="(item, index) in navList" :key="index" @click="changeNav(index)">
        {{ item.name }}
      </div>
    </div>
    <div class="button-wrapper">
      <div class="contact-btn">
        <img class="contact-icon" :src="`${ossPath}/assets/header/contact_icon.png`" />
        <div class="contact-text">400-999-3358</div>
      </div>
      <div class="header-btn trail-btn" @click="openDialog">免费试用</div>
      <div class="header-btn register-btn" @click="toLogin">立即登录</div>
    </div>
    <TrialDialog :showDialog="showDialog" @closeDialog="closeDialog" />
  </div>
</template>

<script>
import TrialDialog from "@/components/TrialDialog";

export default {
  name: "Header",
  components: { TrialDialog },
  data() {
    return {
      navList: [
        {
          name: "首页",
          address: ["/"],
        },
        {
          name: "下载中心",
          address: ["/download"],
        },
        {
          name: "新闻中心",
          address: ["/news", "/newsdetail"],
        },
        {
          name: "关于我们",
          address: ["/about"],
        },
      ],
      activeIndex: 0,
      backgroundColor: "transparent", // 头部背景颜色
      showDialog: false,
    };
  },
  watch: {
    $route(to, from) {
      this.detectNav();
    },
  },
  created() {
    this.detectNav();
  },
  // 挂载时监听页面滚动
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  // 销毁时取消监听
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    detectNav() {
      this.activeIndex = this.navList.findIndex((item) =>
        item.address.some((addr) => {
          const regex = new RegExp(`^${addr}(/|$)`); // 匹配以 addr 为开头的路径，后面可以跟着斜杠或路径结束
          return regex.test(this.$route.path);
        })
      );
    },
    // 滚动页面时触发导航变色
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // 设置背景颜色的透明度
      if (scrollTop >= 34) {
        this.backgroundColor = "#ffffff";
      } else if (scrollTop < 34) {
        this.backgroundColor = "transparent";
      }
    },
    // 路由跳转
    changeNav(index) {
      if (index == this.activeIndex && this.$route.path == this.navList[this.activeIndex].address[0]) return;
      this.activeIndex = index;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$router.push(this.navList[this.activeIndex].address[0]);
    },
    // 跳转登录页
    toLogin() {
      window.open("https://saas.zheguyun.com", "_blank");
    },
    // 开启表单对话框
    openDialog() {
      this.showDialog = true;
    },
    // 关闭表单对话框
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 34px 60px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  z-index: 999;
  transition: background-color 0.7s;
  .header-logo {
    flex: none;
    width: 143px;
    height: 36px;
    margin-right: 50px;
    cursor: pointer;
  }
  .nav-list {
    display: flex;
    flex: auto;
    .nav-item {
      height: 36px;
      cursor: pointer;
      position: relative;
      box-sizing: border-box;
      font-size: 16px;
      color: #666666;
      padding-top: 2px;
      line-height: 34px;
      white-space: nowrap;
      &:not(:last-child) {
        margin-right: 48px;
      }
      &.nav-item-active {
        color: #3470ff;
        &::after {
          content: "";
          width: 100%;
          height: 2px;
          left: 0;
          bottom: -2px;
          position: absolute;
          background: #3470ff;
          border-radius: 6px;
        }
      }
    }
  }
  .button-wrapper {
    display: flex;
    flex: none;
    justify-items: end;
    .contact-btn {
      width: 137px;
      height: 36px;
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 14px;
      border: 1px solid #3470ff;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #3470ff;
      user-select: text;
      -webkit-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      -o-user-select: text;
      .contact-icon {
        width: 14px;
        height: 15px;
        margin-right: 5px;
      }
    }
    .header-btn {
      width: 90px;
      height: 36px;
      border-radius: 4px;
      box-sizing: border-box;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      cursor: pointer;
    }
    .trail-btn {
      background-color: #3470ff;
      color: #ffffff;
      &:hover {
        border: 1px solid #3470ff;
        color: #3470ff;
        background-color: transparent;
      }
      margin-left: 10px;
    }
    .register-btn {
      border: 1px solid #3470ff;
      color: #3470ff;
      &:hover {
        background-color: #3470ff;
        color: #ffffff;
      }
      margin-left: 10px;
    }
  }
}
</style>
