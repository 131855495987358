<template>
  <div class="m-trial-dialog" @click="closeDialog">
    <div class="m-trial-content" @click.stop>
      <img class="m-trial-close" :src="`${ossPath}/assets/footer/close_icon.png`" @click="closeDialog" />
      <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="200px">
        <el-form-item prop="contacter" label="您的姓名">
          <el-input v-model="form.contacter" autocomplete="off" placeholder="请输入您的姓名"></el-input>
        </el-form-item>
        <el-form-item prop="contacter_phone" label="联系方式">
          <el-input v-model="form.contacter_phone" autocomplete="off" placeholder="请输入您的联系方式"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <div class="footer-btn" @click="closeDialog">取 消</div>
        <div class="footer-btn" @click="submit">确 定</div>
      </div>
    </div>
  </div>
</template>

<script>
import { submit } from "@/api/api";

export default {
  name: "M_TrialDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        contacter: "",
        contacter_phone: "",
      },
      rules: {
        contacter: [{ required: true, message: "请输入联系人称呼", trigger: "blur" }],
        contacter_phone: [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
          { pattern: /^1[3|5|7|8|9]\d{9}$/, message: "请输入正确的手机号码", trigger: "blur" },
        ],
      },
      isSubmitting: false,
    };
  },
  methods: {
    submit() {
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      this.$refs.form.validate((valid) => {
        if (valid) {
          submit(this.form)
            .then((res) => {
              this.$message({
                message: "提交成功",
                type: "info",
                duration: 2000,
              });
              this.isSubmitting = false;
              this.$emit("closeDialog");
            })
            .catch((err) => {
              this.$message({
                message: err.msg || "提交失败，请稍后再试",
                type: "info",
                duration: 2000,
              });
              this.isSubmitting = false;
            });
        } else {
          this.isSubmitting = false;
        }
      });
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
.m-trial-dialog {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002;
  .m-trial-content {
    width: 885px;
    height: 560px;
    background: #ffffff;
    border-radius: 30px;
    position: relative;
    .m-trial-close {
      position: absolute;
      top: 50px;
      right: 50px;
      width: 40px;
      height: 40px;
    }
    /deep/ .el-form {
      margin: 150px 32px 0;
      .el-form-item {
        margin-bottom: 48px;
        .el-form-item__label {
          width: 240px !important;
          font-size: 40px;
          color: #222222;
          line-height: 80px;
          padding: 0 16px 0 0;
        }
        .el-form-item__content {
          margin-left: 240px !important;
          line-height: 60px;
          .el-input__inner {
            display: block;
            height: 80px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #e4e6ee;
            border-radius: 12px;
            box-sizing: border-box;
            font-size: 34px;
            color: #666666;
            line-height: 40px;
            padding: 0 10px;
            &::placeholder {
              color: #c3c3c3;
            }
          }
          .el-form-item__error {
            font-size: 30px;
            padding-top: 8px;
          }
        }
      }
    }
    .dialog-footer {
      display: flex;
      margin-top: 60px;
      .footer-btn {
        width: 50%;
        height: 136px;
        border-radius: 12px;
        font-size: 40px;
        color: #747c94;
        line-height: 112px;
        text-align: center;
        &:last-child {
          margin-left: 8px;
          color: #3470ff;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 4px;
            height: 82px;
            left: -2px;
            top: 0;
            bottom: 0;
            margin: auto;
            background: #747c94;
            opacity: 0.4;
          }
        }
      }
    }
  }
}
</style>
