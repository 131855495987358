<template>
  <div class="scroll-wrapper news-wrapper">
    <div class="top-wrapper">
      <div class="bg"></div>
      <div class="title">新闻中心</div>
      <div class="subtitle">了解企业最新资讯动态</div>
    </div>
    <div class="hightlight-news">
      <el-carousel ref="carousel" arrow="never" height="1077px" trigger="click" :interval="5000"
        indicator-position="outside">
        <el-carousel-item v-for="item in topNewsList" :key="item.id">
          <div class="swiper-item" @click="toDetail(item.id)">
            <el-image class="swiper-item-img" :src="item.thumbnail" fit="cover"></el-image>
            <div class="swiper-item-info">
              <div class="swiper-item-title">{{ item.post_title }}</div>
              <div class="swiper-item-detail">{{ item.post_excerpt }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="search-row">
      <el-input placeholder="请输入关键词搜索" v-model="keyword" @keyup.enter.native="search">
        <img class="search-icon" slot="suffix" :src="`${ossPath}/assets/news/search_icon.png`" />
      </el-input>
      <div class="nav-list">
        <div :class="['nav-item', index == activeNavIndex ? 'active-nav-item' : '']"
          v-for="(item, index) in categoryList" :key="index" @click="changeNav(index)">
          <div class="nav-item-title">{{ item.name }}</div>
          <div class="active-bar"></div>
        </div>
      </div>
    </div>
    <div class="news-list" v-if="newsList.length">
      <div class="news-item" v-for="item in newsList" :key="item.id" @click="toDetail(item.id)">
        <el-image class="news-img" :src="item.thumbnail" fit="cover" v-if="item.thumbnail"></el-image>
        <div class="news-info-wrapper">
          <div class="news-date">
            <div class="year-month">{{ item.year }}.{{ item.month }}</div>
            <div class="day">{{ item.day }}</div>
          </div>
          <div class="news-info">
            <div class="news-title">{{ item.post_title }}</div>
            <div class="news-detail">{{ item.post_excerpt }}</div>
          </div>
        </div>
      </div>
    </div>
    <el-empty description="暂无新闻" v-else></el-empty>
    <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
      :currentPage="currentPage" :pageSize="pageSize" :total="totalNum" :pagerCount="5"> </el-pagination>
    <div class="footer-placeholder"></div>
    <MToolBar />
  </div>
</template>

<script>
import { getCategories, getArticles } from "@/api/api";
import { api_url } from "@/utils/index";
import MToolBar from "@/components/ToolBar/m_index.vue";

export default {
  name: "m_News",
  components: { MToolBar },
  data() {
    return {
      categoryList: [],
      topNewsList: [],
      newsList: [],
      activeNavIndex: 0,
      keyword: "",
      currentPage: 1,
      pageSize: 5,
      totalNum: 0,
    };
  },
  created() {
    this.getCategories();
    this.getRecommendNews();
  },
  methods: {
    changeNav(index) {
      if (this.activeNavIndex == index) return;
      this.activeNavIndex = index;
      this.keyword = "";
      this.search();
    },
    toDetail(id) {
      this.$router.push(`/m_newsdetail/${id}`);
    },
    getCategories() {
      getCategories()
        .then((res) => {
          this.categoryList = res.list;
          this.search();
        })
        .catch((err) => { });
    },
    getRecommendNews() {
      getArticles({ recommended: 1 })
        .then((res) => {
          res.articles.forEach((item) => {
            item.thumbnail = `${api_url}${item.thumbnail}`;
          });
          this.topNewsList = res.articles;
        })
        .catch((err) => { });
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    getList() {
      let categoryId = this.categoryList[this.activeNavIndex].id;
      getArticles({ page: this.currentPage, limit: this.pageSize, keyword: this.keyword || undefined, category_ids: categoryId })
        .then((res) => {
          res.articles.forEach((item) => {
            let date = new Date(item.published_time * 1000);
            item.year = date.getFullYear();
            item.month = (date.getMonth() + 1).toString().padStart(2, "0");
            item.day = date.getDate().toString().padStart(2, "0");
            item.thumbnail = `${api_url}${item.thumbnail}`;
          });
          this.newsList = res.articles;
          this.totalNum = res.total;
        })
        .catch((err) => { });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/m_news.scss";
@import "@/assets/scss/m_scroll-wrapper.scss";

.news-wrapper {
  .hightlight-news {
    margin: 80px auto 0;
    width: fit-content;
    background: #ffffff;
    box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.04);
    border-radius: 30px;
    padding: 40px;
    box-sizing: border-box;

    /deep/.el-carousel {
      overflow: hidden;
      height: 997px;

      .el-carousel__container {
        width: 925px;
        height: 875px !important;
      }

      .el-carousel__indicators {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 30px;

        .el-carousel__indicator {
          padding: 12px 10px;

          .el-carousel__button {
            width: 60px;
            height: 20px;
            background: #e6e6e6;
            border-radius: 60px;
            opacity: 1;
          }

          &.is-active {
            .el-carousel__button {
              background: #3470ff;
            }
          }
        }
      }
    }

    .swiper-item {
      display: flex;
      flex-direction: column;
      height: 100%;

      .swiper-item-img {
        width: 100%;
        height: 578px;
        border-radius: 16px;
      }

      .swiper-item-info {
        .swiper-item-title {
          margin-top: 50px;
          width: 100%;
          font-weight: bold;
          font-size: 48px;
          color: #222222;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
        }

        .swiper-item-detail {
          width: 100%;
          margin-top: 30px;
          font-size: 42px;
          color: #666666;
          line-height: 64px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          word-break: break-all;
        }
      }
    }
  }

  .search-row {
    width: 1005px;
    margin: 60px auto 0;

    /deep/ .el-input {
      display: block;

      .el-input__inner {
        height: 120px;
        border-radius: 20px;
        border: 3px solid #e6e6e6;
        padding-left: 40px;
        padding-right: 124px;
        font-size: 36px;
        line-height: 52px;
        color: #666666;

        &::placeholder {
          color: #c3c3c3;
        }
      }

      .el-input__suffix {
        width: 44px;
        height: 44px;
        right: 40px;
        top: 0;
        bottom: 0;
        margin: auto;

        .el-input__suffix-inner,
        .el-input__suffix-inner .search-icon {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }

    .nav-list {
      display: flex;
      align-items: center;
      margin-top: 60px;

      .nav-item {
        cursor: pointer;
        position: relative;
        flex: auto;

        .nav-item-title {
          font-size: 42px;
          color: #222222;
          line-height: 61px;
          transition: all 0.2s;
          text-align: center;
        }

        &.active-nav-item {
          .nav-item-title {
            font-weight: bold;
            color: #3470ff;
          }

          .active-bar {
            width: 100px;
          }
        }

        .active-bar {
          pointer-events: none;
          position: absolute;
          width: 0;
          height: 6px;
          background: #3470ff;
          border-radius: 6px;
          left: 0;
          right: 0;
          bottom: -26px;
          margin: auto;
          transition: width 0.2s;
        }
      }
    }
  }

  .news-list {
    width: 1005px;
    margin: 0 auto;

    .news-item {
      cursor: pointer;
      padding: 60px 0;

      &:not(:last-child) {
        border-bottom: 3px solid #e6e6e6;
      }

      .news-img {
        width: 100%;
        height: 565px;
        border-radius: 10px;
        display: block;
      }

      .news-info-wrapper {
        display: flex;
        margin-top: 40px;

        .news-date {
          width: 160px;
          height: 182px;
          background: #ffffff;
          border-radius: 20px;
          border: 3px solid #c3c3c3;
          box-sizing: border-box;
          color: #999999;
          text-align: center;
          margin-right: 40px;

          .year-month {
            margin-top: 36px;
            font-size: 30px;
            line-height: 44px;
          }

          .day {
            font-weight: bold;
            font-size: 46px;
            line-height: 66px;
            margin-top: 6px;
          }
        }

        .news-info {
          flex: 1;
          min-width: 0;

          .news-title {
            font-weight: bold;
            font-size: 42px;
            color: #222222;
            line-height: 61px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
          }

          .news-detail {
            margin-top: 20px;
            font-size: 32px;
            color: #666666;
            line-height: 51px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            word-break: break-all;
          }
        }
      }
    }
  }

  /deep/ .el-empty {
    padding: 60px 0;

    .el-empty__image {
      width: 320px;
    }

    .el-empty__description {
      margin-top: 40px;

      p {
        font-size: 42px;
        color: #666666;
      }
    }
  }

  /deep/ .el-pagination {
    padding: 0;
    margin: 60px auto 120px;
    // width: 1005px;
    // display: flex;
    width: fit-content;

    .btn-prev,
    .btn-next {
      margin: 0;
      background: none;
      min-width: 0;
      height: 100px;
      border-radius: 0;
    }

    .number {
      flex: auto;
      width: 100px;
      height: 100px;
      background: #ffffff;
      border-radius: 10px;
      border: 2px solid #d6d6d6;
      box-sizing: border-box;
      padding: 0;
      font-size: 42px;
      color: #222222 !important;
      line-height: 100px;
      font-weight: 400;
      margin: 0 12px;
      min-width: 0;

      &.active {
        border-color: #3470ff;
        color: #ffffff !important;
        background-color: #3470ff !important;
      }
    }

    .el-icon {
      width: 100px;
      height: 100px;
      background: #ffffff;
      border-radius: 10px;
      border: 2px solid #d6d6d6;
      box-sizing: border-box;
      padding: 0;
      color: #222222 !important;
      margin: 0 12px;
      min-width: 0;

      &::before {
        display: block;
        height: 100px;
        line-height: 100px;
        font-size: 42px;
      }

      &.el-icon-d-arrow-right::before,
      &.el-icon-d-arrow-left::before {
        content: "\e794";
      }
    }
  }
}
</style>
