import axios from "axios";

// 1. 利用axios对象的方法create，创建一个axios实例
const requests = axios.create({
  // 配置对象
  // baseURL: "http://lvchongofficial.ceshi.zheguyun.com/api", // 测试路径
  baseURL: "/api", // 正式路径
  timeout: 5000, // 请求超时的时间
});
// 2. 请求拦截器
requests.interceptors.request.use((config) => {
  return config; // config:配置对象，其属性headers请求头很重要
});
// 3. 响应拦截器
requests.interceptors.response.use(
  // 成功的回调函数
  (res) => {
    // 请求成功
    if (res.data.code == 1) {
      return res.data.data;
    } else {
      return Promise.reject(res.data);
    }
  },
  // 失败的回调函数
  (res) => {
    return Promise.reject(res);
  }
);

// 对外暴露
export default requests;
