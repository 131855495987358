// export const api_url = "http://lvchongofficial.ceshi.zheguyun.com";  // 测试路径
export const api_url = "/api"  //正式路径

export function isMobile() {
  return (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    ) !== null
  );
}

export function addTimestamp(url) {
  const timestamp = new Date().getTime();
  const urlWithTimestamp = url.includes('?') ? `${url}&t=${timestamp}` : `${url}?t=${timestamp}`;
  return urlWithTimestamp;
}