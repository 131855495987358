<template>
  <div class="top-btn" @click="backTop" v-if="show">
    <img class="top-icon" :src="`${ossPath}/assets/toolbar/m_top_icon.png`" />
  </div>
</template>

<script>
export default {
  name: "m_ToolBar",
  data() {
    return {
      show: false,
      topInterval: null, // 返回顶部定时器
      scrollElement: null,
    };
  },
  mounted() {
    this.scrollElement = document.getElementsByClassName("scroll-wrapper")[0];
    this.scrollElement.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 返回顶部
    backTop() {
      if (this.topInterval) return;
      let top = this.scrollElement.scrollTop;
      let speed = top / 30;
      // 实现滚动效果
      this.topInterval = setInterval(() => {
        this.scrollElement.scrollTop = top -= speed;
        if (top <= 0) {
          clearInterval(this.topInterval);
          this.topInterval = null;
        }
      }, 10);
    },
    handleScroll() {
      this.show = this.scrollElement.scrollTop > 50;
    },
  },
  beforeDestroy() {
    if (this.topInterval) {
      clearInterval(this.topInterval);
      this.topInterval = null;
    }
    this.scrollElement.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.top-btn {
  position: fixed;
  width: 160px;
  height: 180px;
  right: 0;
  bottom: 460px;
  margin-bottom: env(safe-area-inset-bottom);
  background: #ffffff;
  box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.08);
  border-radius: 30px 0px 0px 30px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  .top-icon {
    width: 72px;
    height: 72px;
  }
}
</style>
