<template>
  <div class="download-wrapper">
    <div class="title">多终端下载</div>
    <div class="subtitle">给您带来快捷的设计项目体验</div>
    <div class="card-wrapper">
      <div class="card-item" v-for="(item, index) in cardList" :key="index">
        <img class="card-icon" :src="`${ossPath}/assets/download/${item.icon}_icon.png`" />
        <div class="card-title">{{ item.title }}</div>
        <div class="card-subtitle">{{ item.subtitle }}</div>
        <div class="card-btn" v-if="index != 2" @click="download(item)">
          <img class="card-btn-icon" :src="`${ossPath}/assets/download/download_icon.png`" />
          <div class="card-btn-text">{{ item.btnText }}</div>
        </div>
        <img class="card-qrcode" :src="`${ossPath}/assets/download/miniprogram_code.jpg`" v-else />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Download",
  data() {
    return {
      cardList: [
        {
          icon: "apple",
          title: "MacOS",
          subtitle: "Mac12.0以上\n支持Inter芯片和M芯片",
          btnText: "Mac版",
          filename: "鹧鸪云仿真系统-1.0.0.dmg",
          downloadUrl: "https://47-96-92-76.oss-cn-hangzhou.aliyuncs.com/lvchong/%E7%BB%BF%E8%99%AB%E4%BB%BF%E7%9C%9F%E7%B3%BB%E7%BB%9F-1.0.0.dmg",
        },
        {
          icon: "windows",
          title: "Windows",
          subtitle: "windows10以上",
          btnText: "Windows  64位",
          filename: "鹧鸪云仿真系统 Setup 1.0.0.exe",
          downloadUrl:
            "https://47-96-92-76.oss-cn-hangzhou.aliyuncs.com/lvchong/%E7%BB%BF%E8%99%AB%E4%BB%BF%E7%9C%9F%E7%B3%BB%E7%BB%9F%20Setup%201.0.0.exe",
        },
        {
          icon: "miniprogram",
          title: "微信小程序",
          subtitle: "微信扫一扫即可使用",
        },
      ],
    };
  },
  methods: {
    download(item) {
      let filename = item.filename;
      let a = document.createElement("a");
      a.href = item.downloadUrl;
      a.download = filename;
      a.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.download-wrapper {
  width: 100%;
  height: 930px;
  background: linear-gradient(227deg, #cfdeff 0%, #e1f0f8 50%, #fcf8ed 100%);
  padding-top: 164px;
  .title {
    font-weight: bold;
    font-size: 54px;
    color: #222222;
    line-height: 79px;
    text-align: center;
  }
  .subtitle {
    margin-top: 40px;
    font-size: 20px;
    color: #666666;
    line-height: 29px;
    text-align: center;
  }
  .card-wrapper {
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    .card-item {
      width: 320px;
      height: 420px;
      background: #ffffff;
      box-shadow: 0px 3px 16px 1px rgba(52, 112, 255, 0.08);
      border-radius: 16px;
      // padding-top: 60px;
      box-sizing: border-box;
      &:not(:last-child) {
        margin-right: 50px;
      }
      .card-icon {
        width: 60px;
        height: 60px;
        margin: 60px auto 0;
        display: block;
      }
      .card-title {
        margin-top: 20px;
        font-weight: 500;
        font-size: 28px;
        color: #222222;
        text-align: center;
        line-height: 40px;
      }
      .card-subtitle {
        margin-top: 16px;
        font-size: 14px;
        color: #999999;
        text-align: center;
        line-height: 20px;
        white-space: pre-wrap;
        height: 40px;
      }
      .card-btn {
        width: 180px;
        height: 52px;
        margin: 30px auto 0;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #3470ff;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .card-btn-icon {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }
        .card-btn-text {
          font-size: 14px;
          color: #3470ff;
        }
      }
      .card-qrcode {
        margin: 20px auto 0;
        width: 140px;
        height: 140px;
        display: block;
      }
    }
  }
}
</style>
