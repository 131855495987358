<template>
  <div class="scroll-wrapper home-wrapper">
    <div class="section section-1">
      <div class="section-bg"></div>
      <div class="title">国产自研新能源仿真系统</div>
      <div class="subtitle">对标国际企业发电量算法精准度</div>
      <div class="button-wrapper">
        <div class="home-btn demo-btn" @click="openTrialDialog">预约演示</div>
        <div class="home-btn contact-btn" @click="toLogin">立即登录</div>
      </div>
      <img class="illustration" :src="`${ossPath}/assets/home/m_img.png`" />
    </div>
    <div class="section section-2">
      <div class="section-title">
        <div class="title-text">专注于新能源行业的PPT大全</div>
        <img class="title-icon" :src="`${ossPath}/assets/home/template_icon.png`" />
      </div>
      <div class="section-subtitle">不断更新模板，支持个性化修改，适配不同行业，让您的方案与众不同</div>
      <div class="swiper-outside">
        <swiper class="swiper-no-swiping" :options="swiperOption" ref="tempalteSwiper">
          <swiper-slide v-for="item in 15" :key="item">
            <img class="swiper-item" :src="`${ossPath}/assets/home/template_swiper/swiper_${item}.png`" />
          </swiper-slide>
        </swiper>
      </div>
      <div class="btn-gradient" @click="toTemplate">立即体验模版 >></div>
    </div>
    <div class="section section-3">
      <img class="deco-1" :src="`${ossPath}/assets/home/img_dot.png`" />
      <img class="deco-2" :src="`${ossPath}/assets/home/img_dot.png`" />
      <div class="section-title">
        <div class="title-text">快速了解光伏设计踏勘系统</div>
        <img class="title-icon" :src="`${ossPath}/assets/home/check_icon.png`" />
      </div>
      <div class="video" @click="toggleVideo">
        <img class="video-bg" :src="`${ossPath}/assets/home/video_bg.png`" />
        <img class="video-icon" :src="`${ossPath}/assets/home/video_icon.png`" />
        <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
      </div>
    </div>
    <div class="section section-4">
      <div class="section-bg"></div>
      <div class="section-title">
        <div class="title-text">智能化能源仿真系统</div>
        <img class="title-icon" :src="`${ossPath}/assets/home/geal_icon.png`" />
      </div>
      <div class="nav-wrapper">
        <div class="nav-list-wrapper" ref="navListWrapper">
          <div class="nav-list">
            <div
              :class="['nav-item', index == activeNavIndex ? 'active-nav-item' : '']"
              v-for="(item, index) in navList"
              :key="index"
              @click="changeNav(index)"
            >
              <img class="nav-icon" :src="`${ossPath}/assets/home/icon_${index + 1}.png`" />
              <div class="nav-title">{{ item.navTitle }}</div>
              <div class="active-bar" v-if="index == activeNavIndex"></div>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <el-carousel ref="carousel" :autoplay="false" arrow="never" :loop="false" indicator-position="none" height="1200px">
          <el-carousel-item class="detail" v-for="(item, index) in navList" :key="index">
            <div class="detail-title">{{ navList[index].title }}</div>
            <div class="detail-detail">{{ navList[index].detail }}</div>
            <img class="detail-img" :src="`${ossPath}/assets/home/detail_img_${index + 1}.png`" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="section section-5">
      <div class="section-bg"></div>
      <div class="section-title">
        <div class="title-text">帮助企业解决发展难题</div>
        <img class="title-icon" :src="`${ossPath}/assets/home/bulb_icon.png`" />
      </div>
      <div class="swiper-wrapper">
        <img class="arrow-icon" :src="`${ossPath}/assets/home/m_left_arrow_icon.png`" @click="minusNavIndex1" />
        <el-carousel
          ref="carousel2"
          @change="cardIndexChange"
          :autoplay="true"
          arrow="never"
          :loop="true"
          indicator-position="outside"
          trigger="click"
          height="1006px"
        >
          <el-carousel-item class="card-item" v-for="(item, index) in cardList" :key="index">
            <div class="card-item-content">
              <div class="bg-wrapper">
                <img class="card-bg" :src="`${ossPath}/assets/home/domestic_bg_big.png`" />
              </div>
              <div class="card-title-row">
                <img class="card-icon" :src="`${ossPath}/assets/home/${item.icon}_icon_active.png`" />
                <div class="card-title">{{ item.title }}</div>
              </div>
              <div class="card-subtitle">{{ item.subtitle }}</div>
              <div class="card-detail">
                <div class="card-detail-title">{{ item.cardTitle }}</div>
                <div class="card-detail-detail">{{ item.cardDetail }}</div>
                <div class="card-detail-list">
                  <div class="card-detail-item" v-for="(item2, index2) in item.cardList" :key="index2">
                    <img class="card-detail-item-icon" :src="`${ossPath}/assets/home/${item.icon}_icon_${index2 + 1}.png`" />
                    <div class="card-detail-item-name">{{ item2 }}</div>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <img class="arrow-icon" :src="`${ossPath}/assets/home/m_right_arrow_icon.png`" @click="addNavIndex1" />
      </div>
      <div class="btn-gradient" @click="openContactDialog">定制专属解决方案</div>
    </div>
    <div class="section section-6">
      <div class="section-bg"></div>
      <div class="section-title">
        <div class="title-text">持续不断技术革新</div>
        <img class="title-icon" :src="`${ossPath}/assets/home/dev_icon.png`" />
      </div>
      <div class="nav-list">
        <div :class="['nav-item', index == activeNavIndex2 ? 'active-nav-item' : '']" v-for="(item, index) in navList2" :key="index" @click="changeNav2(index)">
          {{ item.navTitle }}
        </div>
        <div :class="['active-bar', `active-bar-${activeNavIndex2}`]"></div>
      </div>
      <div class="swiper-wrapper">
        <el-carousel ref="carousel3" :autoplay="false" arrow="never" :loop="false" indicator-position="none" height="1500px">
          <el-carousel-item v-for="(item, index) in navList2" :key="index">
            <div class="swiper-item">
              <img class="swiper-item-bg" :src="`${ossPath}/assets/home/m_swiper_${index + 1}.png`" />
              <div class="swiper-item-mask" :style="{ background: item.background }"></div>
              <div class="swiper-item-title">{{ item.title }}</div>
              <div class="swiper-item-info">{{ item.info }}</div>
              <div class="swiper-item-number-list">
                <div class="swiper-item-number-wrapper" v-for="(item2, index2) in item.numberList" :key="index2">
                  <div class="swiper-item-number-item">
                    <div class="swiper-item-number-number">{{ item2.number }}</div>
                    <div class="swiper-item-number-name">{{ item2.numberName }}</div>
                  </div>
                  <div class="divider" v-if="index2 != item.numberList.length - 1"></div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="footer-placeholder"></div>
    <mTrialDialog v-if="showTrialDialog" @closeDialog="closeTrialDialog" />
    <mContactDialog v-if="showContactDialog" @closeDialog="closeContactDialog" />
    <MToolBar />
  </div>
</template>

<script>
import mTrialDialog from "@/components/TrialDialog/m_index.vue";
import mContactDialog from "@/components/ContactDialog/m_index.vue";
import MToolBar from "@/components/ToolBar/m_index.vue";
// 播放器
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
require("@/plugin/zh-CN.js");
// swiper
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { addTimestamp } from "@/utils/index"

export default {
  name: "m_Home",
  components: { mTrialDialog, mContactDialog, swiper, swiperSlide, videoPlayer, MToolBar },
  data() {
    return {
      showTrialDialog: false,
      showContactDialog: false,
      // swiper相关配置
      swiperOption: {
        slidesPerView: 1.6, // 设置slider容器能够同时显示的slides数量(carousel模式)
        slidesPerGroup: 1, // 在carousel mode下定义slides的数量多少为一组
        loop: true, // 设置为true 则开启loop(无限循环)模式
        freeMode: true, //【关键】设置为true则变为free模式
        speed: 6000, //【关键】匀速时间
        autoplay: {
          // 自动播放
          delay: 0, //【关键】自动切换的时间间隔，单位ms
        },
        resistanceRatio: 0,
      },
      // 播放器相关配置
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //视频播放速度
        autoplay: false, // 如果true，浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        // preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）。
        language: "zh-CN",
        // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）。
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 资源格式写法：'video/mp4'，否则控制台会出现notSupportedMessage设置的错误。
            src: addTimestamp("https://47-96-92-76.oss-cn-hangzhou.aliyuncs.com/lvchong/video.mp4"),
          },
        ],
        width: 1005,
        height: 566, // 设置高度，fluid需要设置成flase
        notSupportedMessage: "此视频暂无法播放...", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        nonIconControl: false,
        controlBar: false,
      },
      // 区域4菜单栏
      navList: [
        {
          navTitle: "PPT方案库",
          title: "PPT方案库",
          detail:
            "针对不同类型企业定制专属方案模板，更能展示诚意与专业，在竞争中脱颖而出，模板已经支持：酒店、产业园、美术馆、各类工厂、学校、政府、写字楼、医院等，让新手一键生成、快速编辑、降低成本。",
        },
        {
          navTitle: "气象仿真系统",
          title: "气象仿真系统",
          detail:
            "依据Meteonorm与Solargis气象站数据，真实还原项目所在地气象情况，分析出辐照情况、日照量、风速、海拔、气温、空气浑浊度、地平面远阴影、水平面辐照等数据，成为计算发电量重要依据。",
        },
        {
          navTitle: "核心算法",
          title: "核心算法",
          detail:
            "绿虫发电量自研算法，该算法从设计之初就摒弃了传统预测方法中的诸多局限性，转而采用多维度、多源（40多种因数）数据的融合分析，确保了对光伏发电量预测的全面性和准确性，最终结果对标国际同类企业水平。",
        },
        {
          navTitle: "政策匹配",
          title: "政策匹配",
          detail: "紧跟政策变化，新增各省加权电价（尖峰平谷电价）库、受限区域、政策地图等功能，导入用电量得出消纳比例，与项目融合匹配，生成最优解决方案。",
        },
        {
          navTitle: "智能设计",
          title: "智能设计",
          detail:
            "傻瓜式设计软件，支持导入手绘与CAD图片，可以对混凝土、平房、屋脊、阳光房、彩钢瓦等屋顶进行精准设计，并且可以设置超铺、空间预留、运维通道、障碍物、阴影参照物等，智能匹配逆变器、线缆、储能设备等。最终生成3D效果，增加客户满意度。",
        },
        {
          navTitle: "项目管理",
          title: "项目管理",
          detail: "创建各地不同的项目信息，可以针对项目进行细致的收资与勘探，快速跟踪16个流程进度情况，真正做到对每一个项目进行全面监管与推进。",
        },
        {
          navTitle: "设备大全",
          title: "设备大全",
          detail: "强大的设备库，拥有上万款组件、逆变器、微型逆变器、线缆、变压器、储能电池等各类库，支持自由导入、删除、修改等，满足不同客户需求。",
        },
        {
          navTitle: "工程造价",
          title: "工程造价",
          detail: "自动生成报价清单，所有项目施工材料列表式呈现，并且可以对每一项进行修改、删除和调整，在项目前期，就可以对项目投资情况，有着清晰的了解。",
        },
        {
          navTitle: "经济概算",
          title: "经济概算",
          detail:
            "可以对户用光伏、工商业光伏与储能项目情况，根据不同投资方式进行细致分析，支持企业自投、融资租赁、电费打折、电站赠送等投资方式，帮助企业快速评估风险。",
        },
      ],
      activeNavIndex: 0,
      navInterval: null,
      // 区域5卡片组
      cardList: [
        {
          icon: "domestic",
          title: "国内",
          subtitle: "精准化、全面化、多样化",
          cardTitle: "不断适应行业发展",
          cardDetail: "根据国内政策特点，绿虫科技帮助企业项目快速落地，减少前期方案制作时间，并且一键输出完整方案，打造专属PPT，让您脱颖而出。",
          cardList: ["气象仿真", "投融测算", "傻瓜设计", "政策地图", "可研报告", "消纳分析"],
        },
        {
          icon: "oversea",
          title: "出海",
          subtitle: "全球化、简单化、定制化",
          cardTitle: "助力全球业务发展",
          cardDetail: "随着国内业务逐渐成熟，企业需要更广阔发展空间，绿虫科技帮助企业根据不同国家不同语言，快速定制海外业务系统，为您出海添砖加瓦。",
          cardList: ["气象仿真", "适应国家", "语言调整", "出海营销", "官网搭建", "卫星设计"],
        },
        {
          icon: "empower",
          title: "赋能",
          subtitle: "携手共创未来",
          cardTitle: "一条龙解决方案",
          cardDetail: "从业务管理、方案设计、施工监管到日常运维，绿虫科技可以做到全流程管理，全方位赋能企业发展，在新人培训、项目促成发挥出关键作用。",
          cardList: ["渠道建立", "流程管理", "傻瓜设计", "项目周期", "投融测算", "施工监督"],
        },
        {
          icon: "flex",
          title: "转型",
          subtitle: "新业态、新发展",
          cardTitle: "开启全新发展之路",
          cardDetail: "持续扩展新项目、新业务，是企业生存之道，绿虫科技坚持守正创新，从客户实际发展需求出发，不断在专业领域深耕细作，为企业发展保驾护航。",
          cardList: ["储能算法", "逆变配比", "发电算法", "铺设算法", "用电负载", "阴影算法"],
        },
      ],
      activeNavIndexCard: 0,
      // 区域6 菜单栏
      navList2: [
        {
          navTitle: "客户概况",
          title: "打造客户满意的产品",
          info: "绿虫科技坚持客户需求优先，不断聆听客户心声，对标世界一流企业产品和服务，得到客户广泛认可，平均更新频率一周一次，做到紧跟市场变化。",
          numberList: [
            {
              numberName: "大客户选择",
              number: "200+",
            },
            {
              numberName: "项目数量",
              number: "5000+",
            },
          ],
          btnText: "立即咨询 >",
          background: "linear-gradient( 90deg, #5B9CFF 0%, rgba(91,156,255,0) 100%)",
        },
        {
          navTitle: "技术革新",
          title: "勇于弃旧迎新",
          info: "绿虫科技在技术创新、产品革新中，尝试使用新技术、新框架、新理念，实现产品突飞猛进的发展，我们不断在践行企业文化“用心做好每一个功能”的信念。",
          numberList: [
            {
              numberName: "大版本更新",
              number: "14+",
            },
            {
              numberName: "小版本迭代",
              number: "125+",
            },
          ],
          btnText: "立即咨询   >",
          background: "linear-gradient( 90deg, #9789E5 0%, rgba(151,137,229,0) 100%)",
        },
        {
          navTitle: "人工智能",
          title: "融合AI技术",
          info: "绿虫科技重视与AI技术融合，已经在部署AI+光伏、AI+储能、AI+微电网等技术探索，已经初步利用在业务分析、智能设计、发电量算法中，未来会持续发力。",
          numberList: [],
          btnText: "立即咨询   >",
          background: "linear-gradient( 90deg, #E9A558 0%, rgba(233,165,88,0) 100%)",
        },
      ],
      activeNavIndex2: 0,
    };
  },
  mounted() {
    this.setNavInterval();
  },
  beforeDestroy() {
    this.clearNavInterval();
  },
  methods: {
    openTrialDialog() {
      this.showTrialDialog = true;
    },
    closeTrialDialog() {
      this.showTrialDialog = false;
    },
    openContactDialog() {
      this.showContactDialog = true;
    },
    closeContactDialog() {
      this.showContactDialog = false;
    },
    // 跳转模板
    toTemplate() {
      window.open("https://saas.zheguyun.com/login", "_blank");
    },
    // 跳转登录页
    toLogin() {
      window.open("https://saas.zheguyun.com", "_blank");
    },
    toggleVideo() {
      let player = this.$refs.videoPlayer.player;
      if (player.paused()) {
        player.play();
      } else {
        player.pause();
      }
    },
    // 区域4
    setNavInterval() {
      this.navInterval = setInterval(() => {
        this.nextNav();
      }, 3000);
    },
    clearNavInterval() {
      if (this.navInterval) {
        clearInterval(this.navInterval);
        this.navInterval = null;
      }
    },
    nextNav() {
      this.activeNavIndex++;
      if (this.activeNavIndex > this.navList.length - 1) {
        this.activeNavIndex = 0;
      }
      this.setNav();
    },
    changeNav(index) {
      if (this.activeNavIndex == index) return;
      this.activeNavIndex = index;
      this.setNav();
      this.clearNavInterval();
      this.setNavInterval();
    },
    setNav() {
      this.$refs.carousel.setActiveItem(this.activeNavIndex);
      // 计算偏移量
      let navListWrapper = this.$refs.navListWrapper;
      const NavListStyle = window.getComputedStyle(navListWrapper.children[0]);
      const NavItemStyle = window.getComputedStyle(navListWrapper.children[0].children[0]);
      let scrollLeft =
        parseFloat(NavListStyle.paddingLeft) +
        (this.activeNavIndex - 1) * (parseFloat(NavItemStyle.paddingLeft) + parseFloat(NavItemStyle.width) + parseFloat(NavItemStyle.paddingRight));
      navListWrapper.scrollTo({
        left: scrollLeft,
        // behavior: "smooth",
      });
    },
    // 区域6
    changeNav2(index) {
      this.activeNavIndex2 = index;
      this.$refs.carousel3.setActiveItem(this.activeNavIndex2);
    },
    // 区域5
    minusNavIndex1() {
      this.activeNavIndexCard--;
      if (this.activeNavIndexCard < 0) {
        this.activeNavIndexCard = 1;
      }
      this.$refs.carousel2.setActiveItem(this.activeNavIndexCard);
    },
    addNavIndex1() {
      this.activeNavIndexCard++;
      if (this.activeNavIndexCard > 3) {
        this.activeNavIndexCard = 0;
      }
      this.$refs.carousel2.setActiveItem(this.activeNavIndexCard);
    },
    cardIndexChange(index) {
      this.activeNavIndexCard = index;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/m_section-title.scss";
@import "@/assets/scss/m_scroll-wrapper.scss";
.home-wrapper {
  .section {
    width: 100%;
    position: relative;
    padding-top: 178px;
    box-sizing: border-box;
    .section-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
    .btn-gradient {
      width: 460px;
      height: 120px;
      margin: 100px auto 0;
      background: linear-gradient(180deg, #5b9cff 0%, #7b5bff 100%);
      box-shadow: 0px 3px 10px 1px rgba(52, 112, 255, 0.26);
      border-radius: 16px;
      font-size: 42px;
      color: #ffffff;
      text-align: center;
      line-height: 120px;
    }
  }
  .section-1 {
    height: 1836px;
    padding-top: 164px;
    .section-bg {
      background: url("#{$oss-path}/assets/home/m_bg.png") no-repeat center center / cover;
    }
    .title {
      font-weight: bold;
      font-size: 68px;
      color: #222222;
      text-align: center;
      line-height: 99px;
    }
    .subtitle {
      margin-top: 60px;
      font-weight: 500;
      font-size: 36px;
      color: #222222;
      line-height: 52px;
      text-align: center;
    }
    .button-wrapper {
      margin: 80px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .home-btn {
        width: 360px;
        height: 120px;
        box-sizing: border-box;
        border-radius: 12px;
        font-size: 40px;
        text-align: center;
        line-height: 120px;
      }
      .demo-btn {
        background: #ffffff;
        border: 3px solid #3470ff;
        color: #3470ff;
      }
      .contact-btn {
        margin-left: 60px;
        background: linear-gradient(180deg, #5b9cff 0%, #7b5bff 100%);
        border: none;
        color: #ffffff;
      }
    }
    .illustration {
      margin: 100px auto 0;
      display: block;
      width: 1005px;
      height: 1005px;
    }
  }
  .section-2 {
    background-color: #fff;
    height: 1584px;
    .section-subtitle {
      margin: 56px auto 0;
      font-size: 40px;
      width: 848px;
      color: #666666;
      text-align: center;
      line-height: 63px;
    }
    .swiper-outside {
      position: relative;
      .swiper-container {
        width: 100%;
        margin-top: 80px;
        /deep/ .swiper-wrapper {
          -webkit-transition-timing-function: linear !important; /*之前是ease-out*/
          -moz-transition-timing-function: linear !important;
          -ms-transition-timing-function: linear !important;
          -o-transition-timing-function: linear !important;
          transition-timing-function: linear !important;
        }
        .swiper-slide {
          .swiper-item {
            width: 580px;
            height: 744px;
          }
        }
      }
    }
  }
  .section-3 {
    height: 1100px;
    background: linear-gradient(27deg, #f2f7ff 0%, #fefeff 100%);
    overflow: hidden;
    .deco-1 {
      position: absolute;
      left: -220px;
      bottom: -200px;
      width: 610px;
      height: 610px;
    }
    .deco-2 {
      position: absolute;
      top: 180px;
      right: -110px;
      width: 356px;
      height: 356px;
    }
    .video {
      width: 1005px;
      height: 566px;
      margin: 120px auto 0;
      position: relative;
      .video-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        pointer-events: none;
      }
      .video-icon {
        position: absolute;
        width: 142px;
        height: 112px;
        left: -20px;
        top: -61px;
      }
      /deep/ .video-js {
        border-radius: 35px;
        width: 1005px;
        height: 566px;
        overflow: hidden;
        pointer-events: none;
        .vjs-big-play-button {
          background: url("#{$oss-path}/assets/home/play_icon_big.png") no-repeat center center / contain;
          height: 140px !important;
          width: 140px !important;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto !important;
          border: none;
          .vjs-icon-placeholder {
            display: none;
          }
        }
      }
    }
  }
  .section-4 {
    height: 2000px;
    .section-bg {
      background: url("#{$oss-path}/assets/home/m_bg_4.png") no-repeat center center / cover;
    }
    .nav-wrapper {
      margin-top: 100px;
      .nav-list-wrapper {
        width: 100%;
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none; /* IE 10+ */
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
      }
      .nav-list {
        display: flex;
        position: relative;
        padding: 0 10px;
        width: fit-content;
        .nav-item {
          flex: none;
          padding: 20px 35px 28px;
          position: relative;
          .nav-icon {
            width: 100px;
            height: 100px;
            margin: 0 auto 20px;
            display: block;
          }
          .nav-title {
            font-size: 42px;
            color: #666666;
            line-height: 61px;
            width: 252px;
            text-align: center;
          }
          .active-bar {
            position: absolute;
            width: 156px;
            height: 8px;
            background: #3470ff;
            border-radius: 7px;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }
          &.active-nav-item .nav-title {
            color: #3470ff;
          }
        }
      }
    }
    .divider {
      margin-top: 20px;
      width: 100%;
      height: 3px;
      background: #e6e6e6;
    }
    /deep/ .el-carousel {
      margin-top: 80px;
      .el-carousel__container {
        height: 1300px !important;
      }
    }
    .detail {
      width: 100%;
      padding: 0 60px;
      box-sizing: border-box;
      .detail-title {
        font-weight: bold;
        font-size: 52px;
        color: #222222;
        line-height: 75px;
      }
      .detail-detail {
        margin-top: 40px;
        font-size: 42px;
        color: #666666;
        line-height: 75px;
        word-break: break-all;
      }
      .detail-img {
        margin-top: 100px;
        width: 1005px;
        height: 678px;
        margin-right: 30px;
      }
    }
  }
  .section-5 {
    height: 1750px;
    .section-bg {
      background: url("#{$oss-path}/assets/home/m_bg_5.png") no-repeat center center / cover;
    }
    .swiper-wrapper {
      position: relative;
      height: fit-content;
      .arrow-icon {
        position: absolute;
        width: 120px;
        height: 120px;
        top: 508px;
        cursor: pointer;
        z-index: 5;
        box-shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.08);
        border-radius: 50%;
        &:first-child {
          left: 45px;
        }
        &:last-child {
          right: 45px;
        }
      }
    }
    /deep/ .el-carousel {
      margin: 40px auto 0;
      width: 1000px;
      height: 1240px;
      .el-carousel__container {
        height: 1106px !important;
      }
      .el-carousel__indicators {
        height: 44px;
        margin-top: 30px;
        .el-carousel__indicator {
          width: 60px;
          height: 20px;
          padding: 12px 18px;
          &.is-active .el-carousel__button {
            background: #3470ff;
            opacity: 1;
          }
          .el-carousel__button {
            width: 60px;
            height: 20px;
            border-radius: 60px;
            background: #e6e6e6;
          }
        }
      }
      .card-item {
        .card-item-content {
          width: 945px;
          height: 1006px;
          box-shadow: 0px 3px 20px 1px rgba(52, 112, 255, 0.2);
          border-radius: 30px;
          margin: 50px auto 0;
          position: relative;
          padding: 75px;
          box-sizing: border-box;
          .bg-wrapper {
            width: 885px;
            height: 946px;
            border-radius: 30px;
            overflow: hidden;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            .card-bg {
              position: absolute;
              height: 946px;
              left: 0;
              top: 0;
              bottom: 0;
              margin: auto;
            }
          }
          .card-title-row {
            display: flex;
            position: relative;
            align-items: flex-end;
            .card-icon {
              width: 84px;
              height: 84px;
            }
            .card-title {
              font-weight: bold;
              font-size: 52px;
              color: #3470ff;
              line-height: 75px;
              margin-left: 20px;
            }
          }
          .card-subtitle {
            position: relative;
            margin-top: 30px;
            font-size: 42px;
            color: #222222;
            line-height: 61px;
          }
          .card-detail {
            position: relative;
            height: 636px;
            background: linear-gradient(135deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
            padding: 42px 47px 0 64px;
            box-sizing: border-box;
            margin-top: 40px;
            .card-detail-title {
              display: block;
              font-size: 36px;
              color: #222222;
              line-height: 56px;
              position: relative;
              &::before {
                content: "";
                width: 4px;
                height: 40px;
                background: #5b9cff;
                position: absolute;
                left: -34px;
                top: 6px;
              }
            }
            .card-detail-detail {
              display: block;
              font-size: 36px;
              color: #666666;
              line-height: 54px;
              margin-top: 15px;
            }
            .card-detail-list {
              display: flex;
              flex-wrap: wrap;
              position: absolute;
              left: 0;
              bottom: 0;
              width: 802px;
              .card-detail-item {
                width: 254px;
                height: 86px;
                background: #ffffff;
                box-shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.08);
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                &:not(:nth-child(3n + 1)) {
                  margin-left: 20px;
                }
                &:nth-child(n + 4) {
                  margin-top: 30px;
                }
                .card-detail-item-icon {
                  width: 52px;
                  height: 52px;
                  margin-right: 16px;
                }
                .card-detail-item-name {
                  font-size: 34px;
                  color: #222222;
                }
              }
            }
          }
        }
      }
    }
    .btn-gradient {
      margin-top: 12px;
    }
  }
  .section-6 {
    height: 2304px;
    .section-bg {
      background: url("#{$oss-path}/assets/home/m_bg_6.png") no-repeat center center / cover;
    }
    .nav-list {
      display: flex;
      position: relative;
      width: fit-content;
      margin: 100px auto 0;
      .nav-item {
        flex: none;
        width: 300px;
        height: 120px;
        font-size: 42px;
        color: #666666;
        text-align: center;
        line-height: 126px;
        &.active-nav-item {
          color: #ffffff;
        }
        &:not(:first-child) {
          margin-left: 40px;
        }
      }
      .active-bar {
        position: absolute;
        width: 300px;
        height: 120px;
        background: linear-gradient(180deg, #5b9cff 0%, #7b5bff 100%);
        border-radius: 20px;
        top: 0;
        transition: left 0.5s;
        z-index: -1;
        &.active-bar-0 {
          left: 0;
        }
        &.active-bar-1 {
          left: 340px;
        }
        &.active-bar-2 {
          left: 680px;
        }
      }
    }
    .swiper-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 116px;
      height: 1500px;
      /deep/ .el-carousel {
        flex: none;
        .el-carousel__container {
          width: 1005px;
          height: 1500px !important;
        }
      }
      .swiper-item {
        position: relative;
        width: 1005px;
        height: 1500px;
        padding: 104px 60px 0px;
        box-sizing: border-box;
        .swiper-item-bg {
          position: absolute;
          width: 1005px;
          height: 1500px;
          top: 0;
          left: 0;
        }
        .swiper-item-mask {
          position: absolute;
          width: 972px;
          height: 1470px;
          left: 14px;
          top: 12px;
          border-radius: 30px;
        }
        .swiper-item-title {
          position: relative;
          font-weight: 500;
          font-size: 52px;
          color: #ffffff;
          line-height: 75px;
        }
        .swiper-item-info {
          position: relative;
          margin-top: 50px;
          font-size: 36px;
          color: #ffffff;
          line-height: 62px;
        }
        .swiper-item-number-list {
          display: flex;
          align-items: center;
          margin-top: 80px;
          position: relative;
          .swiper-item-number-wrapper {
            display: flex;
            align-items: center;
          }
          .swiper-item-number-item {
            color: #ffffff;
            .swiper-item-number-number {
              font-weight: 500;
              font-size: 80px;
              line-height: 116px;
            }
            .swiper-item-number-name {
              margin-top: 10px;
              font-size: 36px;
              color: #ffffff;
              line-height: 52px;
            }
          }
          .divider {
            width: 4px;
            height: 80px;
            background: #ffffff;
            opacity: 0.6;
            margin: 0 60px;
          }
        }
      }
    }
  }
}
</style>
