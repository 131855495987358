<template>
  <div class="tool-bar">
    <el-popover placement="left" trigger="hover" :visibleArrow="false" popper-class="service-popover" :closeDelay="0">
      <div class="popover-content">
        <div class="popover-title">微信扫一扫，联系客服</div>
        <div class="popover-img-wrapper">
          <img class="popover-img-bg" :src="`${ossPath}/assets/toolbar/qrcode_bg.png`" />
          <img class="popover-img" :src="`${ossPath}/assets/footer/qrcode.png`" />
        </div>
      </div>
      <div class="tool-item" slot="reference" @mouseenter="onToolHover(0)" @mouseleave="resetToolHover(0)">
        <img class="tool_item_icon" :src="`${ossPath}/assets/toolbar/service_icon${isToolHover[0] ? '_active' : ''}.png`" />
        <div class="tool_item_text">咨询客服</div>
      </div>
    </el-popover>
    <el-popover placement="left" trigger="hover" :visibleArrow="false" popper-class="contact-popover" :closeDelay="0">
      <div class="popover-content">
        <div class="popover-title">免费咨询热线</div>
        <div class="popover-phone">400-999-3358</div>
      </div>
      <div class="tool-item" slot="reference" @mouseenter="onToolHover(1)" @mouseleave="resetToolHover(1)">
        <img class="tool_item_icon" :src="`${ossPath}/assets/toolbar/contact_icon${isToolHover[1] ? '_active' : ''}.png`" />
        <div class="tool_item_text">联系电话</div>
      </div>
    </el-popover>
    <div class="tool-item" slot="reference" @mouseenter="onToolHover(2)" @mouseleave="resetToolHover(2)" @click="backTop">
      <img class="tool_item_icon" :src="`${ossPath}/assets/toolbar/top_icon${isToolHover[2] ? '_active' : ''}.png`" />
      <div class="tool_item_text">返回顶部</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToolBar",
  data() {
    return {
      isToolHover: [false, false, false],
      topInterval: null, // 返回顶部定时器
    };
  },
  methods: {
    onToolHover(index) {
      this.$set(this.isToolHover, index, true);
    },
    resetToolHover(index) {
      this.$set(this.isToolHover, index, false);
    },
    // 返回顶部
    backTop() {
      if (this.topInterval) return;
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      let speed = top / 30;
      // 实现滚动效果
      this.topInterval = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= speed;
        if (top <= 0) {
          clearInterval(this.topInterval);
          this.topInterval = null;
        }
      }, 10);
    },
  },
  beforeDestroy() {
    if (this.topInterval) {
      clearInterval(this.topInterval);
      this.topInterval = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.tool-bar {
  position: fixed;
  bottom: 150px;
  right: 20px;
  box-shadow: 0px 3px 16px 1px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  z-index: 999;
  overflow: hidden;
  .tool-item {
    cursor: pointer;
    padding: 20px 10px 14px;
    background: #ffffff;
    color: #666666;
    &:hover {
      color: #3470ff;
      background: #e6eeff;
    }
    .tool_item_icon {
      width: 24px;
      height: 24px;
      margin: 0 auto;
      display: block;
    }
    .tool_item_text {
      font-size: 12px;
      height: 17px;
      margin-top: 4px;
    }
  }
}
</style>
