import Vue from "vue";
import VueRouter from "vue-router";
import { isMobile } from "@/utils";

Vue.use(VueRouter);

import Home from "@/pages/Home";
import m_Home from "@/pages/Home/m_index.vue";
import Download from "@/pages/Download";
import m_Download from "@/pages/Download/m_index.vue";
import News from "@/pages/News";
import m_News from "@/pages/News/m_index.vue";
import NewsDetail from "@/pages/News/detail";
import m_NewsDetail from "@/pages/News/m_detail.vue";
import About from "@/pages/About";
import m_About from "@/pages/About/m_index.vue";

let router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Home,
      meta: { title: "绿虫科技" },
    },
    {
      path: "/download",
      component: Download,
      meta: { title: "下载中心_绿虫科技" },
    },
    {
      path: "/news",
      component: News,
      meta: { title: "新闻中心_绿虫科技" },
    },
    {
      path: "/newsdetail/:id",
      component: NewsDetail,
      meta: { title: "新闻中心_绿虫科技" },
    },
    {
      path: "/about",
      component: About,
      meta: { title: "关于我们_绿虫科技" },
    },
    {
      path: "/m_index",
      component: m_Home,
      meta: { title: "绿虫科技" },
    },
    {
      path: "/m_download",
      component: m_Download,
      meta: { title: "下载中心_绿虫科技" },
    },
    {
      path: "/m_news",
      component: m_News,
      meta: { title: "新闻中心_绿虫科技" },
    },
    {
      path: "/m_newsdetail/:id",
      component: m_NewsDetail,
      meta: { title: "新闻中心_绿虫科技" },
    },
    {
      path: "/m_about",
      component: m_About,
      meta: { title: "关于我们_绿虫科技" },
    },
    // 重定向
    {
      path: "*",
      redirect: "/",
    },
  ],
});

// 路由守卫
router.beforeEach((to, from, next) => {
  // 如果是手机设备并且访问的不是手机页面，跳转到手机页面
  if (isMobile() && to.path.slice(0, 3) !== "/m_") {
    next(to.path == "/" ? "/m_index" : `m_${to.path.slice(1)}`);
  }
  // 如果是非手机设备但访问了手机页面，跳转到首页
  else if (!isMobile() && to.path.slice(0, 3) === "/m_") {
    next(to.path == "/m_index" ? "/" : `/${to.path.slice(3)}`);
  }
  // 正常访问
  else {
    next();
  }
});
// router.afterEach((to, from, next) => {
//   if (to.meta.title) {
//     //判断是否有标题
//     document.title = to.meta.title;
//   }
// });

export default router;
