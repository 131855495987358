<template>
  <el-dialog title="" :visible.sync="showDialog" :before-close="closeDialog" custom-class="contact-dialog" append-to-body>
    <img class="qrcode" :src="`${ossPath}/assets/footer/qrcode.png`" />
    <div class="tip">微信扫一扫加好友，立享优惠活动</div>
    <div class="phone">400-999-3358</div>
  </el-dialog>
</template>

<script>
export default {
  name: "ContactDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>