<template>
  <div class="scroll-wrapper news-wrapper">
    <div class="top-wrapper">
      <div class="bg"></div>
      <div class="title">新闻中心</div>
      <div class="subtitle">了解企业最新资讯动态</div>
    </div>
    <div class="detail-wrapper" v-if="newsDetail">
      <div class="detail-title">{{ newsDetail.post_title }}</div>
      <div class="detail-time">{{ newsDetail.published_time.slice(0, 11).replace(/-/g, ".") }}</div>
      <div class="divider"></div>
      <div class="detail-content" v-html="newsDetail.post_content"></div>
    </div>
    <div class="footer-placeholder"></div>
    <MToolBar />
  </div>
</template>

<script>
import { getArticles, getArticleDetail } from "@/api/api";
import MToolBar from "@/components/ToolBar/m_index.vue";

export default {
  name: "m_NewsDetail",
  components: { MToolBar },
  data() {
    return {
      newsId: this.$route.params.id,
      newsDetail: null,
      allNewsList: [],
      lastNewsInfo: null,
      nextNewsInfo: null,
    };
  },
  created() {
    this.getAllNewsList();
  },
  methods: {
    getAllNewsList() {
      if (!this.$store.state.allNewsList.length) {
        getArticles({ page: 1, limit: 99999 }).then((res) => {
          this.allNewsList = res.articles;
          this.$store.commit("SETALLNEWSLIST", res.articles);
          this.getArticleDetail();
        });
      } else {
        this.allNewsList = this.$store.state.allNewsList;
        this.getArticleDetail();
      }
    },
    getArticleDetail() {
      getArticleDetail(this.newsId)
        .then((res) => {
          this.newsDetail = res;
          let newsIndex = this.allNewsList.findIndex((item) => item.id == this.newsDetail.id);
          if (newsIndex != -1) {
            let lastNewsIndex = newsIndex - 1;
            let nextNewsIndex = newsIndex + 1;
            this.lastNewsInfo = lastNewsIndex >= 0 ? this.allNewsList[lastNewsIndex] : null;
            this.nextNewsInfo = nextNewsIndex <= this.allNewsList.length ? this.allNewsList[nextNewsIndex] : null;
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/m_news.scss";
@import "@/assets/scss/m_scroll-wrapper.scss";
.news-wrapper {
  .detail-wrapper {
    width: 1005px;
    margin: 300px auto 100px;
    .detail-title {
      padding: 0 35px;
      font-weight: bold;
      font-size: 52px;
      color: #000000;
      text-align: center;
      line-height: 175px;
      word-break: break-all;
    }
    .detail-time {
      margin-top: 40px;
      font-size: 36px;
      color: #999999;
      text-align: center;
      line-height: 52px;
      word-break: break-all;
    }
    .divider {
      width: 100%;
      height: 3px;
      background: #e6e6e6;
      margin: 60px 0;
    }
    .detail-content {
      // font-size: 42px;
      color: #444444;
      // line-height: 61px;
      text-indent: 2em;
      /deep/ p {
        margin: 80px 0;
        &:first-child{
          margin-top: 60px;
        }
      }
      /deep/ img{
        max-width: 100% !important;
        height: auto !important;
        display: block;
        margin: 0 auto;
      }
    }
  }
}
</style>
