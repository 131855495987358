videojs.addLanguage("zh-CN",{
 "Play": " ",
 "Pause": " ",
 "Current Time": " ",
 "Duration": " ",
 "Remaining Time": " ",
 "Stream Type": " ",
 "LIVE": " ",
 "Loaded": " ",
 "Progress": " ",
 "Fullscreen": " ",
 "Non-Fullscreen": " ",
 "Mute": " ",
 "Unmute": " ",
 "Playback Rate": " ",
 "Subtitles": " ",
 "subtitles off": " ",
 "Captions": " ",
 "captions off": " ",
 "Chapters": " ",
 "Close Modal Dialog": " ",
 "Descriptions": " ",
 "descriptions off": " ",
 "Audio Track": " ",
 "You aborted the media playback": " ",
 "A network error caused the media download to fail part-way.": " ",
 "The media could not be loaded, either because the server or network failed or because the format is not supported.": " ",
 "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": " ",
 "No compatible source was found for this media.": " ",
 "The media is encrypted and we do not have the keys to decrypt it.": " ",
 "Play Video": " ",
 "Close": " ",
 "Modal Window": " ",
 "This is a modal window": " ",
 "This modal can be closed by pressing the Escape key or activating the close button.": " ",
 ", opens captions settings dialog": ", ",
 ", opens subtitles settings dialog": ", ",
 ", opens descriptions settings dialog": ", ",
 ", selected": ", ",
 "captions settings": " ",
 "Audio Player": " ",
 "Video Player": " ",
 "Replay": " ",
 "Progress Bar": " ",
 "Volume Level": " ",
 "subtitles settings": " ",
 "descriptions settings": " ",
 "Text": " ",
 "White": " ",
 "Black": " ",
 "Red": " ",
 "Green": " ",
 "Blue": " ",
 "Yellow": " ",
 "Magenta": " ",
 "Cyan": " ",
 "Background": " ",
 "Window": " ",
 "Transparent": " ",
 "Semi-Transparent": " ",
 "Opaque": " ",
 "Font Size": " ",
 "Text Edge Style": " ",
 "None": " ",
 "Raised": " ",
 "Depressed": " ",
 "Uniform": " ",
 "Dropshadow": " ",
 "Font Family": " ",
 "Proportional Sans-Serif": " ",
 "Monospace Sans-Serif": " ",
 "Proportional Serif": " ",
 "Monospace Serif": " ",
 "Casual": " ",
 "Script": " ",
 "Small Caps": " ",
 "Reset": " ",
 "restore all settings to the default values": " ",
 "Done": " ",
 "Caption Settings Dialog": " ",
 "Beginning of dialog window. Escape will cancel and close the window.": " ",
 "End of dialog window.": " "
});