<template>
  <div class="home-wrapper">
    <div class="section section-1">
      <div class="section-bg"></div>
      <div class="title">国产自研新能源仿真系统</div>
      <div class="subtitle">对标国际企业发电量算法精准度</div>
      <div class="button-wrapper">
        <div class="home-btn demo-btn" @click="openDialog">预约演示</div>
        <div class="home-btn contact-btn" @click="toLogin">立即登录</div>
      </div>
      <img class="illustration" :src="`${ossPath}/assets/home/img.png`" />
    </div>
    <div class="section section-2">
      <div class="section-title">
        <div class="title-text">专注于新能源行业的PPT大全</div>
        <img class="title-icon" :src="`${ossPath}/assets/home/template_icon.png`" />
      </div>
      <div class="section-subtitle">不断更新模板，支持个性化修改，适配不同行业，让您的方案与众不同</div>
      <div class="swiper-outside" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
        <swiper class="swiper-no-swiping" :options="swiperOption" ref="tempalteSwiper">
          <swiper-slide v-for="item in 15" :key="item">
            <img class="swiper-item" :src="`${ossPath}/assets/home/template_swiper/swiper_${item}.png`" />
          </swiper-slide>
        </swiper>
        <img class="prev-btn" :src="`${ossPath}/assets/home/left_arrow_icon.png`" @click="prevSlide" />
        <img class="next-btn" :src="`${ossPath}/assets/home/right_arrow_icon.png`" @click="nextSlide" />
      </div>
      <div class="btn-gradient" @click="toTemplate">立即体验模版 >></div>
    </div>
    <div class="section section-3">
      <img class="deco-1" :src="`${ossPath}/assets/home/img_dot.png`" />
      <img class="deco-2" :src="`${ossPath}/assets/home/img_dot.png`" />
      <div class="section-title">
        <div class="title-text">快速了解光伏设计踏勘系统</div>
        <img class="title-icon" :src="`${ossPath}/assets/home/check_icon.png`" />
      </div>
      <div class="video">
        <img class="video-bg" :src="`${ossPath}/assets/home/video_bg.png`" />
        <img class="video-icon" :src="`${ossPath}/assets/home/video_icon.png`" />
        <!-- <video class="video-player" autoplay src="https://47-96-92-76.oss-cn-hangzhou.aliyuncs.com/lvchong/LCgfycn.mp4"></video> -->
        <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
          :options="playerOptions"></video-player>
      </div>
    </div>
    <div class="section section-4">
      <div class="section-bg"></div>
      <div class="section-title">
        <div class="title-text">智能化能源仿真系统</div>
        <img class="title-icon" :src="`${ossPath}/assets/home/geal_icon.png`" />
      </div>
      <div class="nav-wrapper">
        <div class="nav-list">
          <div :class="['nav-item', index == activeNavIndex ? 'active-nav-item' : '']" v-for="(item, index) in navList"
            :key="index" @click="changeNav(index)">
            <img class="nav-icon" :src="`${ossPath}/assets/home/icon_${index + 1}.png`" />
            <div class="nav-title">{{ item.navTitle }}</div>
          </div>
          <div :class="['active-bar', `active-bar-${activeNavIndex}`]"></div>
        </div>
        <el-carousel ref="carousel" :autoplay="false" arrow="never" :loop="false" indicator-position="none"
          height="540px">
          <el-carousel-item class="detail" v-for="(item, index) in navList" :key="index">
            <div class="detail-info">
              <div class="detail-title">{{ navList[index].title }}</div>
              <div class="detail-detail">{{ navList[index].detail }}</div>
            </div>
            <img class="detail-img" :src="`${ossPath}/assets/home/detail_img_${index + 1}.png`" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="section section-5">
      <div class="section-bg"></div>
      <div class="section-title">
        <div class="title-text">帮助企业解决发展难题</div>
        <img class="title-icon" :src="`${ossPath}/assets/home/bulb_icon.png`" />
      </div>
      <div class="card-list">
        <div :class="['card-item', index == activeCardIndex ? 'active-card-item' : '']"
          v-for="(item, index) in cardList" :key="index" @click="changeCard(index)">
          <img class="card-bg"
            :src="`${ossPath}/assets/home/${index == activeCardIndex ? 'domestic_bg_big' : `${item.icon}_bg`}.png`" />
          <div class="card-content">
            <img class="card-icon"
              :src="`${ossPath}/assets/home/${item.icon}_icon${index == activeCardIndex ? '_active' : ''}.png`" />
            <div class="card-title">{{ item.title }}</div>
            <div class="card-divider"></div>
            <div class="card-subtitle">{{ item.subtitle }}</div>
            <div class="card-detail">
              <div class="card-detail-title">{{ item.cardTitle }}</div>
              <div class="card-detail-detail">{{ item.cardDetail }}</div>
              <div class="card-detail-list">
                <div class="card-detail-item" v-for="(item2, index2) in item.cardList" :key="index2">
                  <img class="card-detail-item-icon"
                    :src="`${ossPath}/assets/home/${item.icon}_icon_${index2 + 1}.png`" />
                  <div class="card-detail-item-name">{{ item2 }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-gradient" @click="openContactDialog">定制专属解决方案</div>
    </div>
    <div class="section section-6">
      <div class="section-bg"></div>
      <div class="section-title">
        <div class="title-text">持续不断技术革新</div>
        <img class="title-icon" :src="`${ossPath}/assets/home/dev_icon.png`" />
      </div>
      <div class="nav-list">
        <div :class="['nav-item', index == activeNavIndex2 ? 'active-nav-item' : '']" v-for="(item, index) in navList2"
          :key="index" @click="changeNav2(index)">
          {{ item.navTitle }}
        </div>
        <div :class="['active-bar', `active-bar-${activeNavIndex2}`]"></div>
      </div>
      <div class="swiper-wrapper">
        <img class="arrow-icon" :src="`${ossPath}/assets/home/left_arrow_icon.png`" @click="minusNavIndex2" />
        <el-carousel ref="carousel2" :autoplay="false" arrow="never" :loop="false" indicator-position="none"
          height="490px">
          <el-carousel-item v-for="(item, index) in navList2" :key="index">
            <div class="swiper-item">
              <img class="swiper-item-bg" :src="`${ossPath}/assets/home/swiper_${index + 1}.png`" />
              <div class="swiper-item-mask" :style="{ background: item.background }"></div>
              <div class="swiper-item-title">{{ item.title }}</div>
              <div class="swiper-item-info">{{ item.info }}</div>
              <div class="swiper-item-number-list">
                <div class="swiper-item-number-wrapper" v-for="(item2, index2) in item.numberList" :key="index2">
                  <div class="swiper-item-number-item">
                    <div class="swiper-item-number-number">{{ item2.number }}</div>
                    <div class="swiper-item-number-name">{{ item2.numberName }}</div>
                  </div>
                  <div class="divider" v-if="index2 != item.numberList.length - 1"></div>
                </div>
              </div>
              <div class="swiper-item-btn" @click="openContactDialog">{{ item.btnText }}</div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <img class="arrow-icon" :src="`${ossPath}/assets/home/right_arrow_icon.png`" @click="addNavIndex2" />
      </div>
    </div>
    <TrialDialog :showDialog="showDialog" @closeDialog="closeDialog" />
    <ContactDialog :showDialog="showContactDialog" @closeDialog="closeContactDialog" />
  </div>
</template>

<script>
// 自定义组件
import TrialDialog from "@/components/TrialDialog";
import ContactDialog from "@/components/ContactDialog";
// 播放器
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
require("@/plugin/zh-CN.js");
// swiper
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { addTimestamp } from "@/utils/index"

export default {
  name: "Home",
  components: { videoPlayer, swiper, swiperSlide, TrialDialog, ContactDialog },
  data() {
    return {
      // 区域4菜单栏
      navList: [
        {
          navTitle: "PPT方案库",
          title: "PPT方案库",
          detail:
            "针对不同类型企业定制专属方案模板，更能展示诚意与专业，在竞争中脱颖而出，模板已经支持：酒店、产业园、美术馆、各类工厂、学校、政府、写字楼、医院等，让新手一键生成、快速编辑、降低成本。",
        },
        {
          navTitle: "气象仿真系统",
          title: "气象仿真系统",
          detail:
            "依据Meteonorm与Solargis气象站数据，真实还原项目所在地气象情况，分析出辐照情况、日照量、风速、海拔、气温、空气浑浊度、地平面远阴影、水平面辐照等数据，成为计算发电量重要依据。",
        },
        {
          navTitle: "核心算法",
          title: "核心算法",
          detail:
            "绿虫发电量自研算法，该算法从设计之初就摒弃了传统预测方法中的诸多局限性，转而采用多维度、多源（40多种因数）数据的融合分析，确保了对光伏发电量预测的全面性和准确性，最终结果对标国际同类企业水平。",
        },
        {
          navTitle: "政策匹配",
          title: "政策匹配",
          detail: "紧跟政策变化，新增各省加权电价（尖峰平谷电价）库、受限区域、政策地图等功能，导入用电量得出消纳比例，与项目融合匹配，生成最优解决方案。",
        },
        {
          navTitle: "智能设计",
          title: "智能设计",
          detail:
            "傻瓜式设计软件，支持导入手绘与CAD图片，可以对混凝土、平房、屋脊、阳光房、彩钢瓦等屋顶进行精准设计，并且可以设置超铺、空间预留、运维通道、障碍物、阴影参照物等，智能匹配逆变器、线缆、储能设备等。最终生成3D效果，增加客户满意度。",
        },
        {
          navTitle: "项目管理",
          title: "项目管理",
          detail: "创建各地不同的项目信息，可以针对项目进行细致的收资与勘探，快速跟踪16个流程进度情况，真正做到对每一个项目进行全面监管与推进。",
        },
        {
          navTitle: "设备大全",
          title: "设备大全",
          detail: "强大的设备库，拥有上万款组件、逆变器、微型逆变器、线缆、变压器、储能电池等各类库，支持自由导入、删除、修改等，满足不同客户需求。",
        },
        {
          navTitle: "工程造价",
          title: "工程造价",
          detail: "自动生成报价清单，所有项目施工材料列表式呈现，并且可以对每一项进行修改、删除和调整，在项目前期，就可以对项目投资情况，有着清晰的了解。",
        },
        {
          navTitle: "经济概算",
          title: "经济概算",
          detail:
            "可以对户用光伏、工商业光伏与储能项目情况，根据不同投资方式进行细致分析，支持企业自投、融资租赁、电费打折、电站赠送等投资方式，帮助企业快速评估风险。",
        },
      ],
      activeNavIndex: 0,
      navInterval: null,
      // 区域5卡片组
      cardList: [
        {
          icon: "domestic",
          title: "国内",
          subtitle: "精准化、全面化、多样化",
          cardTitle: "不断适应行业发展",
          cardDetail: "根据国内政策特点，绿虫科技帮助企业项目快速落地，减少前期方案制作时间，并且一键输出完整方案，打造专属PPT，让您脱颖而出。",
          cardList: ["气象仿真", "投融测算", "傻瓜设计", "政策地图", "可研报告", "消纳分析"],
        },
        {
          icon: "oversea",
          title: "出海",
          subtitle: "全球化、简单化、定制化",
          cardTitle: "助力全球业务发展",
          cardDetail: "随着国内业务逐渐成熟，企业需要更广阔发展空间，绿虫科技帮助企业根据不同国家不同语言，快速定制海外业务系统，为您出海添砖加瓦。",
          cardList: ["气象仿真", "适应国家", "语言调整", "出海营销", "官网搭建", "卫星设计"],
        },
        {
          icon: "empower",
          title: "赋能",
          subtitle: "携手共创未来",
          cardTitle: "一条龙解决方案",
          cardDetail: "从业务管理、方案设计、施工监管到日常运维，绿虫科技可以做到全流程管理，全方位赋能企业发展，在新人培训、项目促成发挥出关键作用。",
          cardList: ["渠道建立", "流程管理", "傻瓜设计", "项目周期", "投融测算", "施工监督"],
        },
        {
          icon: "flex",
          title: "转型",
          subtitle: "新业态、新发展",
          cardTitle: "开启全新发展之路",
          cardDetail: "持续扩展新项目、新业务，是企业生存之道，绿虫科技坚持守正创新，从客户实际发展需求出发，不断在专业领域深耕细作，为企业发展保驾护航。",
          cardList: ["储能算法", "逆变配比", "发电算法", "铺设算法", "用电负载", "阴影算法"],
        },
      ],
      activeCardIndex: 0,
      cardInterval: null,
      // 区域6 菜单栏
      navList2: [
        {
          navTitle: "客户概况",
          title: "打造客户满意的产品",
          info: "绿虫科技坚持客户需求优先，不断聆听客户心声，对标世界一流企业产品和服务，得到客户广泛认可，平均更新频率一周一次，做到紧跟市场变化。",
          numberList: [
            {
              numberName: "大客户选择",
              number: "200+",
            },
            {
              numberName: "项目数量",
              number: "5000+",
            },
          ],
          btnText: "立即咨询 >",
          background: "linear-gradient( 90deg, #5B9CFF 0%, rgba(91,156,255,0) 100%)",
        },
        {
          navTitle: "技术革新",
          title: "勇于弃旧迎新",
          info: "绿虫科技在技术创新、产品革新中，尝试使用新技术、新框架、新理念，实现产品突飞猛进的发展，我们不断在践行企业文化“用心做好每一个功能”的信念。",
          numberList: [
            {
              numberName: "大版本更新",
              number: "14+",
            },
            {
              numberName: "小版本迭代",
              number: "125+",
            },
          ],
          btnText: "立即咨询   >",
          background: "linear-gradient( 90deg, #9789E5 0%, rgba(151,137,229,0) 100%)",
        },
        {
          navTitle: "人工智能",
          title: "融合AI技术",
          info: "绿虫科技重视与AI技术融合，已经在部署AI+光伏、AI+储能、AI+微电网等技术探索，已经初步利用在业务分析、智能设计、发电量算法中，未来会持续发力。",
          numberList: [],
          btnText: "立即咨询   >",
          background: "linear-gradient( 90deg, #E9A558 0%, rgba(233,165,88,0) 100%)",
        },
      ],
      activeNavIndex2: 0,
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //视频播放速度
        autoplay: false, // 如果true，浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        // preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）。
        language: "zh-CN",
        // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例  用冒号分隔的两个数字（例如"16:9"或"4:3"）。
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句-话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 资源格式写法：'video/mp4'，否则控制台会出现notSupportedMessage设置的错误。
            src: addTimestamp("https://47-96-92-76.oss-cn-hangzhou.aliyuncs.com/lvchong/video.mp4"),
          },
        ],
        // poster: this.mp4Pic, // 视频封面地址
        width: 1200,
        height: 675, // 设置高度，fluid需要设置成flase
        notSupportedMessage: "此视频暂无法播放...", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        nonIconControl: false,
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
          volumePanel: {
            inline: false,
            vertical: true,
          },
        },
      },
      showDialog: false,
      showContactDialog: false,
      swiperOption: {
        slidesPerView: 7, // 设置slider容器能够同时显示的slides数量(carousel模式)
        slidesPerGroup: 1, // 在carousel mode下定义slides的数量多少为一组
        loop: true, // 设置为true 则开启loop(无限循环)模式
        freeMode: true, //【关键】设置为true则变为free模式
        speed: 6000, //【关键】匀速时间
        autoplay: {
          // 自动播放
          delay: 0, //【关键】自动切换的时间间隔，单位ms
        },
        resistanceRatio: 0,
      },
      lastNeedSwiperSpeed: 0,
    };
  },
  mounted() {
    this.setNavInterval();
    this.setCardInterval();
  },
  beforeDestroy() {
    this.clearNavInterval();
    this.clearCardInterval();
  },
  methods: {
    // 区域4
    setNavInterval() {
      this.navInterval = setInterval(() => {
        this.nextNav();
      }, 3000);
    },
    clearNavInterval() {
      if (this.navInterval) {
        clearInterval(this.navInterval);
        this.navInterval = null;
      }
    },
    nextNav() {
      this.activeNavIndex++;
      if (this.activeNavIndex > this.navList.length - 1) {
        this.activeNavIndex = 0;
      }
      this.$refs.carousel.setActiveItem(this.activeNavIndex);
    },
    changeNav(index) {
      if (this.activeNavIndex == index) return;
      this.activeNavIndex = index;
      this.$refs.carousel.setActiveItem(this.activeNavIndex);
      this.clearNavInterval();
      this.setNavInterval();
    },
    // 区域5
    setCardInterval() {
      this.cardInterval = setInterval(() => {
        this.nextCard();
      }, 3000);
    },
    clearCardInterval() {
      if (this.cardInterval) {
        clearInterval(this.cardInterval);
        this.cardInterval = null;
      }
    },
    nextCard() {
      this.activeCardIndex++;
      if (this.activeCardIndex > this.cardList.length - 1) {
        this.activeCardIndex = 0;
      }
    },
    changeCard(index) {
      if (this.activeCardIndex == index) return;
      this.activeCardIndex = index;
      this.clearCardInterval();
      this.setCardInterval();
    },
    // 区域6
    changeNav2(index) {
      this.activeNavIndex2 = index;
      this.$refs.carousel2.setActiveItem(this.activeNavIndex2);
    },
    minusNavIndex2() {
      this.activeNavIndex2--;
      if (this.activeNavIndex2 < 0) {
        this.activeNavIndex2 = 2;
      }
      this.$refs.carousel2.setActiveItem(this.activeNavIndex2);
    },
    addNavIndex2() {
      this.activeNavIndex2++;
      if (this.activeNavIndex2 > 2) {
        this.activeNavIndex2 = 0;
      }
      this.$refs.carousel2.setActiveItem(this.activeNavIndex2);
    },
    // 开启表单对话框
    openDialog() {
      this.showDialog = true;
    },
    // 关闭表单对话框
    closeDialog() {
      this.showDialog = false;
    },
    // 开启联系对话框
    openContactDialog() {
      this.showContactDialog = true;
    },
    // 关闭联系对话框
    closeContactDialog() {
      this.showContactDialog = false;
    },
    // 区域2
    prevSlide() {
      let swiper = this.$refs.tempalteSwiper.swiper;
      swiper.autoplay.stop();
      let lastIndex = swiper.activeIndex - 1;
      if (lastIndex < 0) {
        lastIndex = swiper.slides.length - 1;
      }
      swiper.slideTo(lastIndex, 0);
    },
    nextSlide() {
      let swiper = this.$refs.tempalteSwiper.swiper;
      swiper.autoplay.stop();
      let nextIndex = swiper.activeIndex + 1;
      if (nextIndex > 15 - 1) {
        nextIndex = 0;
      }
      swiper.slideTo(nextIndex, 0);
    },
    onMouseEnter() {
      let swiper = this.$refs.tempalteSwiper.swiper;
      // swiper1.getTranslate()为当前实时的位移，swiper1.translate为过度结束的位移，swiper1.slideWidth为slide的宽度px
      // 计算之后平滑移动到swiper1.translate需要的速度，并储存下来
      this.lastNeedSwiperSpeed =
        (Math.abs(Math.abs(swiper.getTranslate()) - Math.abs(swiper.translate)) / (swiper.slides[0].offsetWidth + swiper.params.spaceBetween)) *
        swiper.params.speed;
      // setTranslate到当前位置，并停止autoplay
      swiper.setTranslate(swiper.getTranslate());
      swiper.autoplay.stop(); // 参考文档：https://www.swiper.com.cn/api/autoplay/112.html
    },
    onMouseLeave() {
      let swiper = this.$refs.tempalteSwiper.swiper;
      // 使用刚才计算出的速度，移动到当前slide，即将当前slide的移动到头部的动画完成，开启autoplay
      swiper.slideTo(swiper.activeIndex, this.lastNeedSwiperSpeed);
      swiper.autoplay.start(); // 参考文档：https://www.swiper.com.cn/api/autoplay/111.html
    },
    // 跳转模板
    toTemplate() {
      window.open("https://saas.zheguyun.com/login", "_blank");
    },
    // 跳转登录页
    toLogin() {
      window.open("https://saas.zheguyun.com", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/section-title.scss";

.home-wrapper {
  .section {
    width: 100%;
    height: 1000px;
    position: relative;
    padding-top: 90px;
    box-sizing: border-box;

    .section-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }

    .btn-gradient {
      width: 220px;
      height: 52px;
      margin: 60px auto 0;
      background: linear-gradient(180deg, #5b9cff 0%, #7b5bff 100%);
      box-shadow: 0px 3px 10px 1px rgba(52, 112, 255, 0.26);
      border-radius: 8px;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
      line-height: 52px;
      cursor: pointer;
    }
  }

  .section-1 {
    padding-top: 164px;
    height: 1080px;

    .section-bg {
      background: url("#{$oss-path}/assets/home/bg.png") no-repeat center center / cover;
    }

    .title {
      font-weight: bold;
      font-size: 48px;
      color: #222222;
      text-align: center;
      line-height: 70px;
    }

    .subtitle {
      margin-top: 20px;
      font-weight: 500;
      font-size: 18px;
      color: #222222;
      line-height: 26px;
      text-align: center;
    }

    .button-wrapper {
      margin: 36px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .home-btn {
        width: 182px;
        height: 56px;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 18px;
        text-align: center;
        line-height: 56px;
        cursor: pointer;
      }

      .demo-btn {
        background: #ffffff;
        border: 1px solid #3470ff;
        color: #3470ff;

        &:hover {
          background: linear-gradient(180deg, #5b9cff 0%, #7b5bff 100%);
          border: none;
          color: #ffffff;
        }
      }

      .contact-btn {
        margin-left: 24px;
        background: linear-gradient(180deg, #5b9cff 0%, #7b5bff 100%);
        border: none;
        color: #ffffff;

        &:hover {
          background: #ffffff;
          border: 1px solid #3470ff;
          color: #3470ff;
        }
      }
    }

    .illustration {
      margin: 60px auto 0;
      display: block;
      width: 1420px;
      height: 520px;
    }
  }

  .section-2 {
    background-color: #fff;
    height: 825px;
    padding-top: 127px;

    .section-subtitle {
      margin-top: 20px;
      font-size: 18px;
      color: #666666;
      text-align: center;
    }

    .swiper-outside {
      position: relative;

      &::before {
        content: "";
        width: 120px;
        height: 308px;
        background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        pointer-events: none;
      }

      &::after {
        content: "";
        width: 120px;
        height: 308px;
        background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        pointer-events: none;
      }

      .swiper-container {
        width: 100%;
        margin-top: 80px;

        /deep/ .swiper-wrapper {
          -webkit-transition-timing-function: linear !important;
          /*之前是ease-out*/
          -moz-transition-timing-function: linear !important;
          -ms-transition-timing-function: linear !important;
          -o-transition-timing-function: linear !important;
          transition-timing-function: linear !important;
        }

        .swiper-slide {

          // width: 240PX !important;
          // height: 308PX !important;
          .swiper-item {
            width: 240px;
            height: 308px;

            &:hover {
              animation: shrink-grow 0.5s ease-out;
              /* 动画触发 */
            }
          }
        }
      }

      .prev-btn,
      .next-btn {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 3;
        cursor: pointer;

        &.prev-btn {
          left: 90px;
        }

        &.next-btn {
          right: 90px;
        }
      }
    }
  }

  .section-3 {
    background: linear-gradient(27deg, #f2f7ff 0%, #fefeff 100%);

    .deco-1 {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 610px;
      height: 610px;
    }

    .deco-2 {
      position: absolute;
      top: 30px;
      right: 200px;
      width: 356px;
      height: 356px;
    }

    .video {
      width: 1200px;
      height: 675px;
      margin: 60px auto 0;
      position: relative;

      .video-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        pointer-events: none;
      }

      .video-icon {
        position: absolute;
        width: 142px;
        height: 112px;
        left: -61px;
        top: -45px;
      }

      /deep/ .video-js {
        border-radius: 35px;
        width: 1200px;
        height: 675px;
        overflow: hidden;

        .vjs-control-text {
          display: none !important;
        }

        &.vjs-fullscreen {
          .vjs-control-bar .vjs-fullscreen-control::after {
            background: url("#{$oss-path}/assets/home/unfullscreen_icon.png") no-repeat center center / contain;
          }
        }

        .vjs-big-play-button {
          background: url("#{$oss-path}/assets/home/play_icon_big.png") no-repeat center center / contain;
          height: 90px !important;
          width: 90px !important;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto !important;
          border: none;

          .vjs-icon-placeholder {
            display: none;
          }
        }

        .vjs-control-bar {
          height: 76px !important;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
          padding: 34px 10px 26px;
          align-items: center;

          .vjs-progress-control {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 59px;
            width: 100%;
            height: 6px !important;
            padding: 0 26px;

            .vjs-progress-holder {
              background: #c3c3c3;
              border-radius: 60px;
              width: 100%;
              height: 100%;
              margin: 0;

              .vjs-load-progress {
                border-radius: 60px;
              }
            }

            .vjs-mouse-display {
              display: none;
            }

            .vjs-play-progress {
              background: #ffffff;
              border-radius: 60px;

              &::before {
                content: "";
                width: 16px;
                height: 16px;
                background: #ffffff;
                box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
                border-radius: 50%;
                top: 0;
                bottom: 0;
                margin: auto;
                right: -8px;
              }

              .vjs-time-tooltip {
                display: none;
              }
            }
          }

          .vjs-play-control {
            width: 48px;
            height: 16px;
            padding: 0 16px;
            background-clip: content-box;
            -webkit-background-clip: content-box;
            -moz-background-clip: content-box;
            -ms-background-clip: content-box;
            -o-background-clip: content-box;
            cursor: pointer;

            .vjs-icon-placeholder {
              display: none;
            }

            &.vjs-paused {
              background: url("#{$oss-path}/assets/home/play_icon_small.png") no-repeat center center / contain;
            }

            &.vjs-playing {
              background: url("#{$oss-path}/assets/home/pause_icon.png") no-repeat center center / contain;
            }
          }

          .vjs-time-control {
            font-size: 14px;
            color: #ffffff;
            line-height: 16px;
            margin: 0;
            width: fit-content;

            &.vjs-time-divider {
              margin-top: -2px;
            }
          }

          .vjs-volume-panel {
            order: 2;
            margin-left: auto;
            width: 16px;

            .vjs-mute-control {
              width: 16px;
              height: 16px;
              padding: 0;

              &::after {
                content: "";
                position: absolute;
                width: 34px;
                height: 31px;
                display: block;
                left: -8px;
                top: -4px;
                background: url("#{$oss-path}/assets/home/volume_icon.png") no-repeat center center / contain;
              }

              &.vjs-vol-0::after {
                background: url("#{$oss-path}/assets/home/mute_icon.png") no-repeat center center / contain;
              }

              .vjs-icon-placeholder {
                display: none;
              }
            }

            .vjs-volume-control {
              width: 36px;
              height: 130px;
              background: #333333;
              border-radius: 4px;
              left: -27px;
              bottom: 163px;
              margin: 0;

              &::after {
                content: "";
                width: 36px;
                height: 33px;
                position: absolute;
                left: 0;
                bottom: -33px;
              }

              .vjs-volume-bar {
                width: 4px;
                height: 80px;
                background: #b6b6b6;
                border-radius: 4px;
                margin: 28px 16px 0;

                .vjs-volume-level {
                  background: #07c160;
                  border-radius: 4px;
                  width: 4px;

                  &::before {
                    content: "";
                    width: 12px;
                    height: 12px;
                    background: #ffffff;
                    border-radius: 50%;
                    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
                    top: -4px;
                    left: -4px;
                  }
                }
              }
            }
          }

          .vjs-fullscreen-control {
            width: 16px;
            margin: 0 16px;

            &::after {
              content: "";
              position: absolute;
              width: 34px;
              height: 34px;
              display: block;
              left: -8px;
              top: -5px;
              background: url("#{$oss-path}/assets/home/fullscreen_icon.png") no-repeat center center / contain;
            }

            .vjs-icon-placeholder {
              display: none;
            }
          }
        }
      }
    }
  }

  .section-4 {
    .section-bg {
      background: url("#{$oss-path}/assets/home/bg_4.png") no-repeat center center / cover;
    }

    .nav-wrapper {
      width: fit-content;
      margin: 56px auto 0;
    }

    .nav-list {
      display: flex;
      position: relative;
      border-bottom: 1px solid #d9dfeb;

      .nav-item {
        flex: none;
        padding: 10px 28px;
        cursor: pointer;

        .nav-icon {
          width: 40px;
          height: 40px;
          margin: 0 auto 10px;
          display: block;
        }

        .nav-title {
          font-size: 16px;
          color: #666666;
          line-height: 24px;
          width: 100px;
          text-align: center;
        }

        &.active-nav-item .nav-title {
          color: #3470ff;
        }

        &:hover {
          .nav-icon {
            animation: shrink-grow 0.5s ease-out;
            /* 动画触发 */
          }

          &:not(.active-nav-item) {
            position: relative;

            .nav-title {
              color: #3470ff;
            }

            ::after {
              content: "";
              position: absolute;
              width: 64px;
              height: 3px;
              background: #3470ff;
              border-radius: 7px;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }
          }
        }
      }

      .active-bar {
        position: absolute;
        width: 64px;
        height: 3px;
        background: #3470ff;
        border-radius: 7px;
        bottom: 0;
        transition: left 0.5s;

        &.active-bar-0 {
          left: 45px;
        }

        &.active-bar-1 {
          left: 201px;
        }

        &.active-bar-2 {
          left: 357px;
        }

        &.active-bar-3 {
          left: 513px;
        }

        &.active-bar-4 {
          left: 669px;
        }

        &.active-bar-5 {
          left: 825px;
        }

        &.active-bar-6 {
          left: 981px;
        }

        &.active-bar-7 {
          left: 1137px;
        }

        &.active-bar-8 {
          left: 1293px;
        }
      }
    }

    /deep/.el-carousel {
      margin-top: 76px;

      .el-carousel__container {
        height: 540px !important;
      }
    }

    .detail {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .detail-info {
        margin-top: 4px;

        .detail-title {
          font-weight: 500;
          font-size: 24px;
          color: #222222;
          line-height: 35px;
        }

        .detail-detail {
          margin-top: 20px;
          width: 522px;
          font-size: 18px;
          color: #666666;
          line-height: 34px;
          word-break: break-all;
        }
      }

      .detail-img {
        width: 800px;
        height: 540px;
        margin-right: 30px;
      }
    }
  }

  .section-5 {
    height: 860px;

    .section-bg {
      background: url("#{$oss-path}/assets/home/bg_5.png") no-repeat center center / cover;
    }

    .card-list {
      margin-top: 59px;
      display: flex;
      justify-content: center;

      .card-item {
        flex: none;
        width: 240px;
        height: 480px;
        position: relative;
        cursor: pointer;
        padding: 30px;
        box-sizing: border-box;
        transition: width 0.5s, padding 0.5s;
        overflow: hidden;

        &:first-child {
          margin-left: 20px;
        }

        &:not(:last-child) {
          margin-right: 33px;
        }

        // 激活前样式及动效
        .card-bg {
          position: absolute;
          width: 270px;
          height: 510px;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-49.5%);
          transition: all 0.5s;
        }

        .card-content {
          position: relative;
          z-index: 1;
        }

        .card-icon {
          width: 52px;
          height: 52px;
        }

        .card-title {
          position: absolute;
          font-weight: bold;
          font-size: 26px;
          color: #ffffff;
          line-height: 37px;
          left: 0;
          top: 62px;
          transition: all 0.8s;
        }

        .card-divider {
          margin-top: 63px;
          width: 100%;
          max-width: 180px;
          height: 2px;
          background: #ffffff;
          opacity: 1;
          transition: all 0.8s;
        }

        .card-subtitle {
          margin-top: 16px;
          font-size: 14px;
          color: #ffffff;
          line-height: 24px;
          transition: all 0.8s;
        }

        .card-detail {
          width: 0;
          height: 0;
          margin-top: 16px;
          opacity: 0;
          transition: width 0.8s, opacity 1.5s;

          .card-detail-title,
          .card-detail-detail,
          .card-detail-list {
            display: none;
          }
        }

        // 激活后样式
        &.active-card-item {
          width: 580px;
          background: #ffffff;
          box-shadow: 0px 3px 10px 1px rgba(52, 112, 255, 0.1);
          border-radius: 10px;
          padding: 40px;

          .card-bg {
            width: 560px;
            height: 460px;
            transform: translateX(-50%) translateY(-50%);
          }

          .card-title {
            color: #3470ff;
            left: 62px;
            top: 15px;
          }

          .card-divider {
            margin-top: 0;
            height: 0;
            width: 0;
            opacity: 0;
          }

          .card-subtitle {
            font-weight: 600;
            font-size: 18px;
            color: #222222;
            line-height: 26px;
          }

          .card-detail {
            width: 500px;
            height: 290px;
            background: linear-gradient(135deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
            padding: 20px;
            box-sizing: border-box;
            opacity: 1;

            .card-detail-title {
              display: block;
              width: 446px;
              font-size: 16px;
              color: #222222;
              line-height: 24px;
              margin-left: 14px;
              position: relative;

              &::before {
                content: "";
                width: 2px;
                height: 16px;
                background: #5b9cff;
                position: absolute;
                left: -13px;
                top: 2px;
              }
            }

            .card-detail-detail {
              display: block;
              width: 446px;
              font-size: 14px;
              color: #666666;
              line-height: 20px;
              margin-top: 8px;
              margin-left: 14px;
            }

            .card-detail-list {
              display: flex;
              width: 422px;
              flex-wrap: wrap;
              position: absolute;
              left: 0;
              bottom: -10px;

              .card-detail-item {
                width: 130px;
                height: 46px;
                background: #ffffff;
                box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.08);
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;

                &:not(:nth-child(3n + 1)) {
                  margin-left: 16px;
                }

                &:nth-child(n + 4) {
                  margin-top: 10px;
                }

                .card-detail-item-icon {
                  width: 26px;
                  height: 26px;
                  margin-right: 8px;
                }

                .card-detail-item-name {
                  font-size: 16px;
                  color: #222222;
                }
              }
            }
          }
        }
      }
    }
  }

  .section-6 {
    height: 900px;

    .section-bg {
      background: url("#{$oss-path}/assets/home/bg_6.png") no-repeat center center / cover;
    }

    .nav-list {
      display: flex;
      position: relative;
      width: fit-content;
      margin: 49px auto 0;

      .nav-item {
        flex: none;
        width: 188px;
        height: 56px;
        cursor: pointer;
        font-size: 22px;
        color: #666666;
        text-align: center;
        line-height: 56px;
        transition: color 0.5s;

        &.active-nav-item {
          color: #ffffff;
        }

        &:not(:first-child) {
          margin-left: 40px;
        }
      }

      .active-bar {
        position: absolute;
        width: 188px;
        height: 56px;
        background: linear-gradient(180deg, #5b9cff 0%, #7b5bff 100%);
        border-radius: 8px;
        top: 0;
        transition: left 0.5s;
        z-index: -1;

        &.active-bar-0 {
          left: 0;
        }

        &.active-bar-1 {
          left: 228px;
        }

        &.active-bar-2 {
          left: 456px;
        }
      }
    }

    .swiper-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 45px;
      height: 490px;

      .arrow-icon {
        width: 90px;
        height: 90px;
        cursor: pointer;

        &:first-child {
          margin-right: 49px;
        }

        &:last-child {
          margin-left: 49px;
        }
      }

      /deep/ .el-carousel {
        flex: none;

        .el-carousel__container {
          width: 1230px;
          height: 490px !important;
        }
      }

      .swiper-item {
        position: relative;
        width: 1230px;
        height: 490px;
        padding: 55px 75px 75px;
        box-sizing: border-box;

        .swiper-item-bg {
          position: absolute;
          width: 1230px;
          height: 490px;
          top: 0;
          left: 0;
        }

        .swiper-item-mask {
          position: absolute;
          width: 1210px;
          height: 460px;
          left: 50%;
          top: 50%;
          transform: translateX(-50.4%) translateY(-50.6%);
          border-radius: 10px;
        }

        .swiper-item-title {
          position: relative;
          font-weight: 500;
          font-size: 22px;
          color: #ffffff;
          line-height: 32px;
        }

        .swiper-item-info {
          position: relative;
          margin-top: 16px;
          width: 379px;
          font-size: 14px;
          color: #ffffff;
          line-height: 22px;
        }

        .swiper-item-number-list {
          display: flex;
          align-items: center;
          position: absolute;
          bottom: 151px;
          left: 75px;

          .swiper-item-number-wrapper {
            display: flex;
            align-items: center;
          }

          .swiper-item-number-item {
            color: #ffffff;

            .swiper-item-number-number {
              font-weight: 500;
              font-size: 30px;
              line-height: 44px;
            }

            .swiper-item-number-name {
              margin-top: 4px;
              font-size: 16px;
              color: #ffffff;
              line-height: 24px;
            }
          }

          .divider {
            width: 2px;
            height: 42px;
            background: #ffffff;
            opacity: 0.6;
            margin: 0 40px;
          }
        }

        .swiper-item-btn {
          position: absolute;
          left: 75px;
          bottom: 75px;
          width: 164px;
          height: 46px;
          background: rgba(255, 255, 255, 0.3);
          border-radius: 8px;
          box-sizing: border-box;
          border: 1px solid #ffffff;
          font-size: 16px;
          color: #ffffff;
          text-align: center;
          line-height: 46px;
          cursor: pointer;

          &:hover {
            background: rgba(255, 255, 255, 0.5);
          }
        }
      }
    }
  }
}

/* 定义缩小再放大的动画 */
@keyframes shrink-grow {
  0% {
    transform: scale(1);
    /* 原始大小 */
  }

  40% {
    transform: scale(0.92);
    /* 缩小 */
  }

  100% {
    transform: scale(1);
    /* 恢复原始大小 */
  }
}
</style>
