<template>
  <div>
    <div class="footer-wrapper">
      <div class="footer-list">
        <div class="footer-item" @click="toWXService">
          <img class="footer-item-icon" :src="`${ossPath}/assets/footer/service_icon.png`" />
          <div class="footer-item-name">咨询客服</div>
        </div>
        <div class="footer-item" @click="openPhoneDialog">
          <img class="footer-item-icon" :src="`${ossPath}/assets/footer/contact_icon.png`" />
          <div class="footer-item-name">联系电话</div>
        </div>
      </div>
    </div>
    <mPhoneDialog v-if="showPhoneDialog" @closeDialog="closePhoneDialog" />
  </div>
</template>

<script>
import mPhoneDialog from "@/components/ContactDialog/m_phone.vue";

export default {
  name: "m_Footer",
  components: { mPhoneDialog },
  data() {
    return {
      showPhoneDialog: false,
    };
  },
  methods: {
    toWXService() {
      window.open("https://work.weixin.qq.com/kfid/kfcacaf21f83ea44919", "_blank");
    },
    openPhoneDialog() {
      this.showPhoneDialog = true;
    },
    closePhoneDialog() {
      this.showPhoneDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-wrapper {
  width: 100%;
  height: 160px;
  padding-bottom: env(safe-area-inset-bottom);
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
  background-color: #ffffff;
  .footer-list {
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #3470ff 0%, #b666ff 100%);
    display: flex;
  }
  .footer-item {
    width: 50%;
    display: flex;
    align-items: center;
    position: relative;
    &:first-child::after {
      content: "";
      position: absolute;
      right: -2px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 4px;
      height: 92px;
      background: #ffffff;
      opacity: 0.4;
    }
    .footer-item-icon {
      width: 60px;
      height: 60px;
      margin-left: 130px;
      margin-right: 30px;
    }
    .footer-item-name {
      font-size: 48px;
      color: #ffffff;
    }
  }
}
</style>
