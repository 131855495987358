import Vue from "vue";
import App from "./App.vue";

import router from "./router";

import "normalize.css/normalize.css";
import "@/assets/scss/popover.scss";
import "@/assets/scss/dialog.scss";
import "@/assets/scss/message.scss";
import "@/assets/scss/m_message.scss";
import "@/plugin/elementUi";
import store from "./store";

Vue.config.productionTip = false;
Vue.prototype.ossPath = "https://47-96-92-76.oss-cn-hangzhou.aliyuncs.com/lvchong/gw"

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
