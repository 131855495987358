<template>
  <div id="app">
    <MHeader v-if="isMobile" />
    <Header v-else />
    <router-view />
    <MFooter v-if="isMobile" />
    <Footer v-else />
    <ToolBar v-if="!isMobile" />
  </div>
</template>

<script>
import Header from "@/components/Header";
import MHeader from "@/components/Header/m_index.vue";
import Footer from "@/components/Footer";
import MFooter from "@/components/Footer/m_index.vue";
import ToolBar from "@/components/ToolBar";
import { isMobile } from "@/utils";
import { debounce } from "lodash";

export default {
  name: "App",
  components: { Header, MHeader, Footer, MFooter, ToolBar },
  data() {
    return {
      isMobile: isMobile(),
    };
  },
  created() {
    window.addEventListener("resize", this.checkDevice);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },
  methods: {
    checkDevice: debounce(function () {
      this.isMobile = isMobile();
      let path = this.$route.path;
      if (this.isMobile && path.slice(0, 3) !== "/m_") {
        this.$router.replace(path === "/" ? "/m_index" : `/m_${path.slice(1)}`);
      } else if (!isMobile() && path.slice(0, 3) === "/m_") {
        this.$router.replace(path == "/m_index" ? "/" : `/${path.slice(3)}`);
      }
    }, 100),
  },
};
</script>

<style>
body {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}
button {
  outline: none;
}
</style>
