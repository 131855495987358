<template>
  <div class="footer-wrapper">
    <div class="top-section">
      <div class="intro">
        <img class="logo" :src="`${ossPath}/assets/footer/logo.png`" />
        <div class="intro-divider"></div>
        <div class="slogan">让光储充设计更简单</div>
      </div>
      <div class="info">
        <div class="info-left">
          <div class="info-left-item" v-for="(item, index) in infoList" :key="index">
            <img class="info-left-icon" :src="`${ossPath}/assets/footer/${item.icon}_icon.png`" />
            <div class="info-left-detail">{{ item.info }}</div>
          </div>
        </div>
        <div class="info-right">
          <div class="nav-list">
            <div class="nav-item" v-for="(item, index) in navList" :key="index" @click="changeRoute(item.address)">{{ item.name }}</div>
          </div>
          <div class="wx">
            <div class="wx-text">联系光伏顾问</div>
            <img class="wx-qrcode" :src="`${ossPath}/assets/footer/qrcode.png`" />
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="copyright">沪ICP备2024091535号-1 &nbsp;&nbsp;&nbsp;&nbsp;上海绿虫信息科技有限公司与江苏绿虫信息科技有限公司版权所有</div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      infoList: [
        {
          icon: "time",
          info: "周一至周六  09:00-18:00",
        },
        {
          icon: "phone",
          info: "400-999-3358",
        },
        {
          icon: "loc",
          info: "江苏省徐州市云龙区云龙华府广场A1栋1601室",
        },
      ],
      navList: [
        {
          name: "首页",
          address: "/",
        },
        {
          name: "下载中心",
          address: "/download",
        },
        {
          name: "新闻中心",
          address: "/news",
        },
        {
          name: "关于我们",
          address: "/about",
        },
      ],
    };
  },
  methods: {
    changeRoute(address) {
      if (this.$route.path == address) return;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$router.push(address);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-wrapper {
  width: 100%;
  height: 396px;
  box-sizing: border-box;
  background: #1b2032;
  padding: 56px 250px 24px;
  .top-section {
    .intro {
      display: flex;
      align-items: center;
      .logo {
        flex: none;
        width: 143px;
        height: 36px;
      }
      .intro-divider {
        flex: none;
        width: 2px;
        height: 20px;
        background: #d6d6d6;
        margin: 4px 20px 0;
      }
      .slogan {
        flex: none;
        font-size: 20px;
        color: #d6d6d6;
        line-height: 30px;
        margin-top: 3px;
      }
    }
    .info {
      display: flex;
      justify-content: space-between;
      margin-top: 37px;
      font-size: 14px;
      color: #ffffff;
      .info-left {
        margin-top: 7px;
        .info-left-item {
          display: flex;
          align-items: center;
          &:not(:last-child) {
            margin-bottom: 40px;
          }
          .info-left-icon {
            flex: none;
            width: 16px;
            height: 16px;
            margin-right: 14px;
            margin-top: -2px;
          }
          .info-left-detail {
            flex: none;
            line-height: 22px;
            user-select: text;
            -webkit-user-select: text;
            -moz-user-select: text;
            -ms-user-select: text;
            -o-user-select: text;
          }
        }
      }
      .info-right {
        flex: none;
        display: flex;
        .nav-list {
          padding: 10px 0;
          height: 100%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .nav-item {
            cursor: pointer;
          }
        }
        .wx {
          margin-left: 100px;
          .wx-text {
            text-align: center;
            line-height: 22px;
            margin-bottom: 6px;
          }
          .wx-qrcode {
            width: 116px;
            height: 116px;
          }
        }
      }
    }
  }
  .divider {
    margin: 50px 0 26px;
    height: 1px;
    background: #ffffff;
    opacity: 0.26;
  }
  .copyright {
    font-size: 14px;
    color: #999999;
    line-height: 22px;
    text-align: center;
    white-space: nowrap;
  }
}
</style>
