import Vue from "vue";
import Vuex from "vuex";

// 使用插件
Vue.use(Vuex);

const state = {
  allNewsList: [],
};
const mutations = {
  SETALLNEWSLIST(state, value) {
    state.allNewsList = value;
  },
};
const actions = {
};
const getters = {};

// 对外暴露 Store 类的一个实例
export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
