<template>
  <div class="m-contact-dialog" @click="closeDialog">
    <div class="m-contact-content" @click.stop>
      <img class="m-contact-close" :src="`${ossPath}/assets/footer/close_icon.png`" @click="closeDialog" />
      <img class="m-contact-qrcode" :src="`${ossPath}/assets/footer/qrcode.png`" />
      <div class="m-contact-title">微信扫一扫加好友，立享优惠活动</div>
      <div class="m-contact-phone">400-999-3358</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "m_ContactDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
.m-contact-dialog {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002;
  .m-contact-content {
    width: 885px;
    height: 650px;
    background: #ffffff;
    border-radius: 30px;
    position: relative;
    .m-contact-close {
      position: absolute;
      top: 50px;
      right: 50px;
      width: 40px;
      height: 40px;
    }
    .m-contact-qrcode {
      margin: 60px auto 0;
      width: 320px;
      height: 320px;
      display: block;
    }
    .m-contact-title {
      margin-top: 40px;
      font-size: 46px;
      color: #222222;
      line-height: 66px;
      text-align: center;
    }
    .m-contact-phone {
      margin-top: 40px;
      font-weight: bold;
      font-size: 60px;
      color: #222222;
      text-align: center;
      line-height: 87px;
      user-select: text;
      -webkit-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      -o-user-select: text;
    }
  }
}
</style>
