import Vue from "vue";
import { Carousel, CarouselItem, Popover, Image, Input, Pagination, Empty, Dialog, Form, FormItem, Message } from "element-ui";
import "element-ui/lib/theme-chalk/index.css"; // 引入 Element UI 的样式

Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);
Vue.component(Popover.name, Popover);
Vue.component(Image.name, Image);
Vue.component(Input.name, Input);
Vue.component(Pagination.name, Pagination);
Vue.component(Empty.name, Empty);
Vue.component(Dialog.name, Dialog);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);

Vue.prototype.$message = Message;