<template>
  <el-dialog title="马上联系您" :visible.sync="showDialog" :before-close="closeDialog" custom-class="trial-dialog" append-to-body>
    <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="200px">
      <el-form-item prop="contacter" label="您的姓名">
        <el-input v-model="form.contacter" autocomplete="off" placeholder="请输入您的姓名"></el-input>
      </el-form-item>
      <el-form-item prop="contacter_phone" label="联系方式">
        <el-input v-model="form.contacter_phone" autocomplete="off" placeholder="请输入您的联系方式"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <div class="footer-btn" @click="closeDialog">取 消</div>
      <div class="footer-btn" @click="submit">确 定</div>
    </div>
  </el-dialog>
</template>

<script>
import { submit } from "@/api/api";

export default {
  name: "TrialDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        contacter: "",
        contacter_phone: "",
      },
      rules: {
        contacter: [{ required: true, message: "请输入联系人称呼", trigger: "blur" }],
        contacter_phone: [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
          { pattern: /^1[3|5|7|8|9]\d{9}$/, message: "请输入正确的手机号码", trigger: "blur" },
        ],
      },
      isSubmitting: false,
    };
  },
  methods: {
    submit() {
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      this.$refs.form.validate((valid) => {
        if (valid) {
          submit(this.form)
            .then((res) => {
              this.$message({
                message: "提交成功",
                type: "success",
                duration: 2000,
              });
              this.isSubmitting = false;
              this.$emit("closeDialog");
            })
            .catch((err) => {
              this.$message({
                message: err.msg || "提交失败，请稍后再试",
                type: "success",
                duration: 2000,
              });
              this.isSubmitting = false;
            });
        } else {
          this.isSubmitting = false;
        }
      });
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>
